import "./Schedule.scss";
import { useSchedule } from "../../../../../../../context/scheduleProvider";
import { useState, useEffect } from "react";
import { FaPlus  } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import Select from "react-select";

const _ScheduleHome = ({ cb }) => {
    return (
        <div className="schedule-home">
            <p>Schedule creator</p>
            <span>
                Here you can create and modify your day schedule,
                so that you can display it using on-screen graphics.
                Click on an item to edit or delete it, or press the 'Get started'
                button to start creating your schedule!
            </span>
            <button onClick={cb}>Get started</button>
        </div>

    );
}

const options = [
    { value: "T", label: "Training" },
    { value: "Q", label: "Qualification" },
    { value: "R", label: "Race" }
];

const getLabel = (value) => {
    const option = options.find(option => option.value === value);
    return option ? option.label : '';
};

const _ScheduleAdd = ({ onAdd }) => {
    const [name, setName] = useState("");
    const [time, setTime] = useState("");
    const [type, setType] = useState(null);

    const handleAdd = () => {
        if (type.value) {
            onAdd(name, time, type.value);
            setName("");
            setTime("");
            setType(null);
        }
    };

    return (
        <div className="schedule-add">
            <p>Add new schedule item</p>
            <input type="text" placeholder="Name" value={name} onChange={e => setName(e.target.value)} />
            <input type="time" placeholder="Start time" value={time} onChange={e => setTime(e.target.value)} />
            <Select
                className="mb-6 w-[250px] text-[#222222]"
                options={options}
                onChange={selectedOption => setType(selectedOption)}
                value={type}
            />
            <button onClick={handleAdd}>Add</button>
        </div>
    );
};


const _ScheduleEdit = ({ onEdit, onDelete, item }) => {
    const [name, setName] = useState(item.name);
    const [time, setTime] = useState(item.time);
    const [type, setType] = useState({ value: item.type, label: getLabel(item.type) });

    useEffect(() => {
        setName(item.name);
        setTime(item.time);
        setType({ value: item.type, label: getLabel(item.type) });
    }, [item]);

    const handleSave = () => {
        onEdit(item, name, time, type.value);
    };

    const handleDelete = () => {
        onDelete(item);
    };

    return (
        <div className="schedule-add">
            <p>Edit selected schedule event</p>
            <input type="text" placeholder="Name" value={name} onChange={e => setName(e.target.value)} />
            <input type="time" placeholder="Start time" value={time} onChange={e => setTime(e.target.value)} />
            <Select
                className="mb-6 w-[250px] text-[#222222]"
                value={type}
                options={options}
                onChange={selectedOption => setType(selectedOption)}
            />
            <button onClick={handleSave}>Save</button>
            <button onClick={handleDelete}>Delete</button>
        </div>
    );
}

const Schedule = ({ socket }) => {

    const { schedule, setNewSchedule } = useSchedule();
    const [ mode, setMode ] = useState("HOME");
    const [ selectedItem, setSelctedItem ] = useState({});

    const getTypeBackground = (type) => {
        switch (type) {
            case "T":
                return "#ff9a62";
            case "Q":
                return "#fede67";
            case "R":
                return "#b6f26a";
            default:
                return "#000000";
        }
    }

    const typeToFull = (type) => {
        switch (type) {
            case "T":
                return "Training";
            case "Q":
                return "Qualification";
            case "R":
                return "Race";
            default: return "";
        }
    }

    const handleAdd = async (name, time, type) => {
        const scheduleCopy = schedule;
        scheduleCopy.push({name, time, type});

        scheduleCopy.sort((a, b) => {
            return a.time.localeCompare(b.time);
        });

        await setNewSchedule(scheduleCopy);
    };

    const handleEdit = async (item, newName, newTime, newType) => {
        const scheduleCopy = schedule.map(scheduleItem =>
            scheduleItem === item ? { name: newName, time: newTime, type: newType } : scheduleItem
        );

        scheduleCopy.sort((a, b) => {
            return a.time.localeCompare(b.time);
        });

        await setNewSchedule(scheduleCopy);
    };

    const handleDelete = async (item) => {
        const scheduleCopy = schedule.filter(scheduleItem => scheduleItem !== item);

        await setNewSchedule(scheduleCopy);
    };

    const purge = async () => {
        await setNewSchedule([]);
        socket.emit("update", {what: 5, data: ['scheduleIndex', 0]}, (response) => {
        });
        socket.emit("update", {what: 5, data: ['scheduleItem', {}]}, (response) => {
        });
        setMode("HOME");
    }

    const modeToComponent = {
        HOME: () => <_ScheduleHome cb={() => setMode("ADD")}/>,
        ADD: () => <_ScheduleAdd onAdd={handleAdd} />,
        EDIT: () => <_ScheduleEdit onEdit={handleEdit} onDelete={handleDelete} item={selectedItem} />
    };

    return (
        <div className="schedule-component">
            <div className="schedule-area">
                <div className="schedule-showcase">
                    <button
                        style={{right: "10px", bottom: "10px"}}
                        title="Add new" onClick={() => setMode("ADD")}><FaPlus /></button>
                    <button
                        style={{left: "10px", bottom: "10px"}}
                        title="Clear" onClick={() => purge()}><MdDelete size={"20px"}/></button>
                    <div className="schedule-showcase-inner">
                    {
                        schedule.map((item, index) =>
                            <div
                                className="schedule-item"
                                key={`schedule_item_comp_${index}`}
                                onClick={() => {
                                    setSelctedItem(item);
                                    setMode("EDIT");
                                }}
                            >
                                <div className="schedule-item-time"><hr/><p>{item.time}</p></div>
                                <span><p>{item.name}</p><p className="absolute right-2 px-2 h-[36px] flex items-center rounded-sm" style={{background: getTypeBackground(item.type)}}>{typeToFull(item.type)}</p></span>
                            </div>
                        )
                    }
                    </div>
                </div>
                <div className="schedule-editor">
                    {
                        modeToComponent[mode]()
                    }
                </div>
            </div>
        </div>
    );
}

export default Schedule;