import { useSelector } from "react-redux";
import React, { useState, useEffect } from "react";
import { classToColor } from "../../../../../../../Functions";
import useAuth from "../../../../../../../hooks/useAuth";
import useAxiosPrivate from "../../../../../../../hooks/useAxiosPrivate";
import { Confirmation } from "../../../ConfirmAction";
import { IoClose } from "react-icons/io5";
import Select from "react-select";

const Interview = React.memo(({ socket }) => {

    const config = useSelector(state => state.config);

    const customInterviewData = config?.customInterviewData;
    const competitorInterviewData = config?.competitorInterviewData;

    const [ name, setName ] = useState(customInterviewData?.name || '');
    const [ description, setDescription ] = useState(customInterviewData?.description || '');
    const [search, setSearch] = useState('');
    const [loadingCompetitors, setLoadingCompetitors] = useState(false);
    const [competitors, setCompetitors] = useState([]);
    const [showActive, setShowActive] = useState(true);
    const [classValue, setClassValue] = useState('');

    const [showConfirmation, setShowConfirmation] = useState(false);


    const filteredCompetitors = competitors?.filter(competitor => {
        const searchString = `${competitor.nr} ${competitor.class} ${competitor.firstname} ${competitor.lastname}`.toLowerCase();
        return searchString.includes(search.toLowerCase());
    });

    const handleCustomSave = () => {
        socket.emit('update', { what: 5, data: ['customInterviewData', {
            name: name, description: description
        }]}, (response) => {});
    }

    const handleCompetitorSave = (competitor) => {
        socket.emit('update', { what: 5, data: ['competitorInterviewData', competitor]}
        , (response) => {});
    }


    const { auth } = useAuth();
    const axiosPrivate = useAxiosPrivate();

    const getCompetitors = async () => {
        setLoadingCompetitors(true);
        try {
            const response = await axiosPrivate.post('/getCompetitors', { userId: auth.userId });
            setCompetitors(response.data.competitors);
        } catch (err) {
            console.error(err);
        } finally {
            setLoadingCompetitors(false);
        }
    }

    // Get available competitors from database
    useEffect(() => {
        getCompetitors();
    }, [config?.competitorClasses]);

    const changeHandler = value => {
        setClassValue(value.value);
    }

    const clearData = async (action) => {
        if (action) {
            try {
                const response = await axiosPrivate.post('/getCompetitors/delete', { userId: auth.userId });
                setCompetitors([]);
                setShowConfirmation(false);
            } catch (err) {
                console.error(err);
            }
        } else {
            setShowConfirmation(false);
        }
    }

    const uniqueClasses = Array.from(new Set(competitors?.map(item => item.class) || []));
    const options = [
        { label: "Any", value: "" },
        ...uniqueClasses?.map(uniqueClass => ({
            label: uniqueClass,
            value: uniqueClass
        }))
    ];

    return (
        <div className="bg-[#020817] w-full h-full overflow-hidden">
            {
                showConfirmation && <Confirmation message="Are you sure you want to clear all data?" callback={clearData}/>
            }
            <div className="w-full h-[calc(100%-50px)] flex gap-2 p-3 text-white ">
                {
                    competitors && showActive ?
                    <div className="w-full h-full p-3">
                        <p className="text-center text-[1rem]">Available for interview</p>
                        <div className="flex items-center gap-3">
                            <div className="relative w-1/2 mb-1">
                                <input
                                    className="border border-[#1c2334] bg-[#151a27] px-1 rounded-md h-[40px] w-full text-white"
                                    type="text"
                                    value={search}
                                    onChange={e => setSearch(e.target.value)} placeholder="Search..."
                                />
                                <p className="absolute right-3 top-1/2 transform -translate-y-1/2 z-[999] text-white cursor-pointer" onClick={() => setSearch("")}><IoClose size={"20px"}/></p>
                            </div>
                            <Select className="h-full text-[#222222] w-1/4" options={options} onChange={changeHandler}/>
                        </div>
                        <div className="h-[calc(100%-50px)] w-full white-slider">
                        {competitorInterviewData && (
                            <div className="mb-3 py-3 z-10 h-[70px] ">
                                <p>Selected for interview</p>
                                <div className="flex items-center gap-2 bg-[#151a27] py-1 rounded-md">
                                <p className="bg-white p-2 rounded-md text-[#222222] ml-1">{competitorInterviewData.nr}</p>
                                <p className="text-white p-2 rounded-md" style={{background: classToColor(competitorInterviewData?.class, uniqueClasses)}}>{competitorInterviewData.class}</p>
                                <p className="text-white">{competitorInterviewData.firstname}</p>
                                <p className="text-white">{competitorInterviewData.lastname}</p>
                                </div>
                            </div>
                        )}
                        <div className="w-full h-3/4 overflow-y-auto">
                        <table className="w-full">
                            <thead className="z-2 bg-[#020817] sticky top-0">
                                <tr className="h-[30px]">
                                    <th className="text-start">Start nr.</th>
                                    <th className="text-start">Class</th>
                                    <th className="text-start">Name</th>
                                    <th className="text-start">Surname</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    filteredCompetitors
                                        ?.filter(comp => classValue ? comp?.class === classValue : comp?.class)
                                        .filter(comp => comp?.nr !== competitorInterviewData?.nr) // Filter out the selected competitor
                                        .map((comp, index) => {
                                            return (
                                                <tr
                                                    key={`competitor_interview_${index}`}
                                                    className={
                                                        `h-[30px] cursor-pointer ${
                                                            comp?.nr === competitorInterviewData?.nr ?
                                                            'bg-[#38466a]' : index % 2 !== 0 ?
                                                            'bg-none' : 'bg-[#151a27]'
                                                        }`
                                                    }
                                                    onClick={() => handleCompetitorSave(comp)}
                                                >
                                                    <td>{comp?.nr}</td>
                                                    <td className="w-[calc(100%-24px)]">
                                                        <div className="w-fit px-3 rounded-md" style={{ background: classToColor(comp?.class, uniqueClasses) }}>
                                                            <span style={{ textShadow: `-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000` }}>
                                                                {comp?.class}
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td>{comp?.firstname}</td>
                                                    <td>{comp?.lastname}</td>
                                                </tr>
                                            );
                                        })
                                }
                            </tbody>
                        </table>
                        </div>
                        </div>
                    </div>
                    : showActive ?
                    <div className="w-full h-full rounded-md border border-[#e4e4e7] p-3 shadow-md flex flex-col items-center justify-center">
                        <p className="text-[1.3rem] font-bold">Wait up!</p>
                        <span>No competitors found yet, check back later.</span>
                    </div>
                    : null
                }
                {
                    !showActive &&
                    <div className="p-3 h-full w-full text-white shadow-md">
                        <p className="text-center text-[1rem]">Custom inverview</p>
                        <div className="flex flex-col items-center gap-2 mt-2">
                            <label htmlFor="name">Name of the person</label>
                            <input
                                className="bg-[#f9f9fa] border border-[#e4e4e7] text-[#222222] rounded-md h-[40px] px-1 w-2/3"
                                type="text"
                                name="name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                            <label htmlFor="description">Description / role / title</label>
                            <input
                                className="bg-[#f9f9fa] border border-[#e4e4e7] text-[#222222] rounded-md h-[40px] px-1 w-2/3"
                                type="text"
                                name="descripton"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                            />
                            <button
                                className="bg-[#5d60ef] text-white rounded-md h-[40px] w-2/3 mt-2"
                                onClick={handleCustomSave}
                            >Save</button>
                        </div>
                    </div>
                }
            </div>
            <div className="w-[calc(100%-24px)] h-[50px] bg-[#020817] ml-3 rounded-t-md flex items-center gap-3 px-3 relative">
                <button style={{background: showActive ? '#5d60ef' : '#151a27'}} className="p-2 rounded-md" onClick={() => setShowActive(true)} disabled={showActive}>Active competitors</button>
                <button style={{background: !showActive ? '#5d60ef' : '#151a27'}} className="p-2 rounded-md" onClick={() => setShowActive(false)} disabled={!showActive}>Custom interview</button>
                <button className="p-2 bg-[#151a27] rounded-md absolute right-3" onClick={() => setShowConfirmation(true)}>Clear data</button>
            </div>
        </div>
    );
});

export default Interview;