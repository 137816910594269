import { combineReducers } from "redux";
import socketReducer from "./socketReducer";
import dataReducer from "./dataReducer";
import configReducer from "./configReducer";
import pageReducer from "./pageReducer";
import errorReducer from "./errorReducer";
import propsReducer from "./customPropsReducer";
import socketLogReducer from "./socketLogReducer";

const rootReducer = combineReducers({
    socket: socketReducer,
    data: dataReducer,
    config: configReducer,
    page: pageReducer,
    error: errorReducer,
    props: propsReducer,
    socketLog: socketLogReducer
});


export default rootReducer;