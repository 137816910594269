import "./Schedule.scss";
import { FaClock } from "react-icons/fa";
import { useState, useEffect, useMemo } from "react";
import { motion, AnimatePresence } from "framer-motion";
import countryList from "react-select-country-list";

export const ScheduleActive = ({ config }) => {

    const data = config?.scheduleItem;
    if (data.type) {
        switch (data.type) {
            case "Q": data.type = "Qualification"; break;
            case "R": data.type = "Race"; break;
            case "T": data.type = "Training"; break;
        }
    }


    if (!data) return <></>;

    switch ( config?.style ) {
        case "k1": return (
            <motion.div
                className="k1_schedule_active absolute text-white"
                style={{
                    left: config?.buttons?.scheduleNow?.left,
                    top: config?.buttons?.scheduleNow?.top
                }}
            >
                <motion.header
                    initial={{clipPath: 'inset(0 100% 0 0)'}}
                    animate={{clipPath: 'inset(0 0% 0 0)', transition: { duration: 0.4 }}}
                    exit={{clipPath: 'inset(0 100% 0 0)', transition: {duration: 0.05 }}}
                >
                    UP NEXT
                </motion.header>
                <motion.div
                    className="k1_schedule_active_content"
                    initial={{clipPath: 'inset(0 0 100% 0)'}}
                    animate={{clipPath: 'inset(0 0 0% 0)', transition: { duration: 0.4, delay: 0.5 }}}
                    exit={{clipPath: 'inset(0 0 100% 0)', transition: {duration: 0.05 }}}
                >
                <motion.span
                    initial={{opacity: 0}}
                    animate={{opacity: 1, transition: { delay: 1 }}}
                    className=""
                >
                    <FaClock color="#ffda05" size={"25px"}/>
                    <h2 className="flex gap-1 ml-2 text-[2.2rem]">{data?.time.split(':')[0]}<h4 className="flashing-colon">:</h4>{data?.time.split(':')[1]}</h2>
                </motion.span>
                <div>
                    <motion.p
                        initial={{clipPath: 'inset(0 100% 0 0)'}}
                        animate={{clipPath: 'inset(0 0% 0 0)', transition: { duration: 0.2, delay: 1 }}}
                        exit={{clipPath: 'inset(0 100% 0 0)', transition: {duration: 0.05 }}}
                    >
                        {data?.name}
                    </motion.p>
                    <motion.p
                        className="!text-[.9rem]"
                        initial={{clipPath: 'inset(0 100% 0 0)'}}
                        animate={{clipPath: 'inset(0 0% 0 0)', transition: { duration: 0.2, delay: 1 }}}
                        exit={{clipPath: 'inset(0 100% 0 0)', transition: {duration: 0.05 }}}
                    >
                        {data?.type}
                    </motion.p>
                </div>
                </motion.div>
            </motion.div>
        );
    }
};

export const ScheduleFull = ({ config, socket, raceData }) => {

    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);

    const scheduleIndex = config?.scheduleIndex;
    const filteredData = useMemo(() => data?.scheduleData?.filter((_, index) => index >= scheduleIndex), [data, scheduleIndex]);

    const countries = useMemo(() => countryList().getValueList(), []);

    const formatDate = (date) => {
        const options = { day: 'numeric', month: 'long', year: 'numeric' };
        return new Date(date).toLocaleDateString('en-US', options);
    };
    const typeToName = (type) => {
        switch (type) {
            case "Q": return "Qualification";
            case "R": return "Race";
            case "T": return "Training";
            default: return type;
        }
    }
    const typeToBg = (type) => {
        switch (type) {
            case "Q": return "#5d60ef";
            case "R": return "#0cf05f";
            case "T": return "#f09c0c";
            default: return "#ea324e";
        }
    }

    useEffect(() => {
        socket.emit("schedule", { command: "GET" }, (response) => {
            setData(response.data);
        });
    }, [config?.scheduleIndex]);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentPage(prevPage => (prevPage + 1) % Math.ceil(filteredData?.length / 6));
        }, 10000);
        return () => clearInterval(interval);
    }, [filteredData]);

    const currentData = filteredData?.slice(currentPage * 6, (currentPage + 1) * 6);

    if (!data) return <></>;

    switch ( config?.style ) {
        case "k1": return (
            <motion.div
                className="k1_schedule_full absolute w-[1200px]"
                style={{
                    left: config?.buttons?.scheduleAll?.left,
                    top: config?.buttons?.scheduleAll?.top
                }}
            >
                <motion.header
                    initial={{clipPath: 'inset(0 100% 0 0)'}}
                    animate={{clipPath: 'inset(0 0% 0 0)', transition: { duration: 0.4 }}}
                    exit={{clipPath: 'inset(0 100% 0 0)', transition: {duration: 0.05 }}}

                    className="w-full bg-[#191921] h-[100px] flex flex-col justify-center text-white text-[1.2rem] px-8 relative"
                >
                    <span className="text-[#ffda05]">EVENT SCHEDULE</span>
                    <p className="text-[2rem]">{raceData?.championship}</p>
                    {
                        config?.raceCountry ?
                        <img
                            src={`https://flagcdn.com/${config?.raceCountry}.svg`}
                            className="h-[75%] bg-cover absolute right-[2rem]"
                        />
                        : <div className="h-full absolute" />
                    }
                </motion.header>
                <motion.div
                    initial={{clipPath: 'inset(0 0 100% 0)'}}
                    animate={{clipPath: 'inset(0 0 0% 0)', transition: { duration: 0.1, delay: 0.4 }}}
                    exit={{clipPath: 'inset(0 0 100% 0)', transition: {duration: 0.05 }}}
                    className="w-full flex justify-between px-8 py-2 relative bg-[#191921] text-white text-[1.15rem]"
                >
                    <p>{formatDate(new Date())}</p>
                    <p>{`${raceData?.track_name}, ${countries?.[config?.raceCountry]}`}</p>
                </motion.div>
                <motion.div
                    initial={{clipPath: 'inset(0 0 100% 0)'}}
                    animate={{clipPath: 'inset(0 0 0% 0)', transition: { duration: 0.1, delay: 0.5 }}}
                    exit={{clipPath: 'inset(0 0 100% 0)', transition: {duration: 0.05 }}}
                    className="w-full h-[10px] bottomBorder bg-[#424256]"
                >
                    <div className="loader h-full"/>
                </motion.div >
                <motion.div
                    initial={{clipPath: 'inset(0 0 100% 0)'}}
                    animate={{clipPath: 'inset(0 0 0% 0)', transition: { duration: 0.1, delay: 0.6 }}}
                    exit={{clipPath: 'inset(0 0 100% 0)', transition: {duration: 0.05 }}}
                    className="w-full max-h-[900px] overflow-hidden bg-[#1919214e]"
                >
                {
                    currentData
                        ?.map((item, index) => {
                            if (item?.time === filteredData?.[0]?.time) {
                                return (
                                    <AnimatePresence>
                                    <motion.li
                                        key={`schedule_full_${index}${currentPage}`}
                                        initial={{clipPath: 'inset(0 100% 0 0)'}}
                                        animate={{clipPath: 'inset(0 0% 0 0)', transition: { duration: 0.1, delay: 0.7 + index * 0.1}}}
                                        exit={{clipPath: 'inset(0 0 100% 0)', transition: {duration: 0.05 }}}
                                        className="w-full h-fit text-white mb-3 list-none"
                                    >
                                        <p className="pl-8 py-2 text-[1.3rem] f1-bold text-[#ffda05] bg-[#191921]">UP NEXT</p>
                                        <div
                                            className="w-full h-[100px] flex items-center justify-between mb-[5px] text-white bg-[#191921e8]"
                                            style={{borderLeft: `${typeToBg(item?.type)} solid 10px`}}
                                        >
                                            <div className="ml-8">
                                                <p className="text-[1.9rem] font-bold">{item?.name}</p>
                                                <p className="text-[1.1rem]">{typeToName(item?.type)}</p>
                                            </div>
                                            <span
                                                className="h-full w-[200px] px-3 flex gap-[5px] items-center justify-center bg-[#ea324e] text-[1.9rem] cool-shape"
                                            >
                                                <>{item?.time.split(':')[0]}<h4 className="flashing-colon">:</h4>{item?.time.split(':')[1]}</>
                                            </span>
                                        </div>
                                    </motion.li>
                                    </AnimatePresence>
                                )
                            }
                            return (
                                <AnimatePresence>
                                <motion.li
                                    initial={{clipPath: 'inset(0 100% 0 0)'}}
                                    animate={{clipPath: 'inset(0 0% 0 0)', transition: { duration: 0.1, delay: 0.7 + index * 0.1 }}}
                                    exit={{clipPath: 'inset(0 0 100% 0)', transition: {duration: 0.05 }}}
                                    className="w-full h-[100px] list-none flex items-center justify-between mb-[5px] text-white bg-[#191921e8]"
                                    key={`schedule_full_${index}${currentPage}`}
                                    style={{borderLeft: `${typeToBg(item?.type)} solid 10px`}}
                                >
                                    <div className="ml-8">
                                        <p className="text-[1.9rem] font-bold">{item?.name}</p>
                                        <p className="text-[1.1rem]">{typeToName(item?.type)}</p>
                                    </div>
                                    <span
                                        className="h-full w-[200px] px-3 flex gap-[5px] items-center justify-center bg-[#ea324e] text-[1.9rem] cool-shape"
                                    >
                                        <>{item?.time.split(':')[0]}<h4 className="flashing-colon">:</h4>{item?.time.split(':')[1]}</>
                                    </span>
                                </motion.li>
                                </AnimatePresence>
                            )
                        })
                }
                </motion.div>
                <motion.div
                    initial={{clipPath: 'inset(100% 0 0 0)'}}
                    animate={{clipPath: 'inset(0 0 0 0)', transition: { duration: 0.1, delay: 0.8 }}}
                    exit={{clipPath: 'inset(100% 0 0 0)', transition: {duration: 0.05 }}}
                    className="w-full h-[50px] bg-[#1b1e23] text-white flex items-center px-4"
                />
            </motion.div>
        );
    }

};