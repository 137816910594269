import "./Interviews.scss";
import { motion } from "framer-motion";

export const InterviewCompetitor = ({ config }) => {

    const data = config?.competitorInterviewData;
    const palette = config?.palette?.palette;

    if (!data) return <></>;

    const animation =
    {
        rotate: [90, 0, 0, 0, 0],
        width: ["110px", "110px", "110px", "600px", "600px"],
        height: ["110px", "110px", "110px", "110px", "110px"],
        backgroundColor: ["#ea324d", "#191921","#191921", "#191921", "#191921"]
    }

    switch ( config?.style ) {
        case "k1": return (
            <motion.div
                className="k1_interview_competitor"
                style={{left: config?.buttons?.interviewCompetitor?.left, top: config?.buttons?.interviewCompetitor?.top}}
            >
                <motion.div
                    initial={{ rotate: 90, width: "101px", height: "101px", backgroundColor: "#ea324d"}}
                    animate={animation}
                    exit={{
                        rotate: [0, 0, 0, 90],
                        opacity: [1, 1, 1, 0],
                        width: ["600px", "101px"],
                        backgroundColor: "#ea324d",
                        transition: { delay: 0.4}}}
                    transition={{ duration: 1.6, times: [0, 0.2, 0.5, 0.6, 1] }}
                >
                    <motion.div
                        className="k1_interview_competitor_content"
                    >
                        <motion.div
                            className="k1_interview_competitor_content_top"
                            initial={{clipPath: 'inset(0 100% 0 0)', backgroundColor: "#ea324d"}}
                            animate={{clipPath: 'inset(0 0% 0 0)', backgroundColor: "#191921", transition: {duration: 0.3, delay: 1}}}
                            exit={{clipPath: 'inset(0 100% 0 0)', backgroundColor: "#ea324d", transition: {delay: 0.2}}}
                        >
                            {/* <span className="k1_interview_pos">{data?.position || "-"}</span> */}
                            <span className="k1_interview_nr">{data?.nr || "-"}</span>
                            <div className="k1_interview_flag">{data.state ?
                                <img src={`https://flagcdn.com/${data.state.toLowerCase()}.svg`} alt="-" />
                                : <div className="img_placeholder" />
                            }</div>
                            <span className="k1_interview_firstname">{data?.firstname}</span>
                            <span className="k1_interview_lastname">{data?.lastname}</span>
                        </motion.div>
                        <motion.p
                            initial={{clipPath: 'inset(0 100% 0 0)', backgroundColor: "#191921"}}
                            animate={{clipPath: 'inset(0 0% 0 0)', backgroundColor: "#ea324d", transition: {duration: 0.3, delay: 1}}}
                            exit={{clipPath: 'inset(0 100% 0 0)', backgroundColor: "#191921"}}
                        >{data?.team}</motion.p>
                    </motion.div>
                </motion.div>
            </motion.div>
        );
        case "v2":
        return (
            <motion.div
                className="absolute w-fit h-fit"
                style={{left: config?.buttons?.interviewCompetitor?.left, top: config?.buttons?.interviewCompetitor?.top}}
            >
                <motion.div
                    initial={{ rotate: 90, width: "101px", height: "101px", backgroundColor: palette?.primary}}
                    animate={{
                        rotate: [90, 0, 0, 0, 0],
                        width: ["110px", "110px", "110px", "600px", "600px"],
                        height: ["110px", "110px", "110px", "110px", "110px"],
                        backgroundColor: [palette?.primary, palette?.secondary, palette?.secondary, palette?.secondary, palette?.secondary]
                    }}
                    exit={{
                        rotate: [0, 0, 0, 90],
                        opacity: [1, 1, 1, 0],
                        width: ["600px", "101px"],
                        backgroundColor: palette?.primary,
                        transition: { delay: 0.4}}}
                    transition={{ duration: 1.6, times: [0, 0.2, 0.5, 0.6, 1] }}
                >
                    <motion.div
                        className="w-full h-1/2 px-2 flex items-center gap-2 text-white font_outfit"
                        initial={{clipPath: 'inset(0 100% 0 0)', backgroundColor: palette?.secondary}}
                        animate={{clipPath: 'inset(0 0% 0 0)', backgroundColor: palette?.primary, transition: {duration: 0.3, delay: 1}}}
                        exit={{clipPath: 'inset(0 100% 0 0)', backgroundColor: palette?.secondary, transition: {delay: 0.2}}}
                    >
                        <span className="text-[1.4rem] font-[700]">{data?.nr || "-"}</span>
                        <div className="w-[40px] h-auto">{data.state ?
                            <img src={`https://flagcdn.com/${data.state.toLowerCase()}.svg`} alt="-" />
                            : <div className="img_placeholder" />
                        }</div>
                        <span className="text-[1.6rem] font-[700]">{data?.firstname}</span>
                        <span className="text-[1.6rem] font-[700]">{data?.lastname}</span>
                    </motion.div>
                    <motion.p
                        className="w-full h-1/2 px-2 flex items-center gap-2 text-white font_outfit text-[1.4rem]"
                        initial={{clipPath: 'inset(0 100% 0 0)', backgroundColor: palette?.accent}}
                        animate={{clipPath: 'inset(0 0% 0 0)', backgroundColor: palette?.secondary, transition: {duration: 0.3, delay: 1}}}
                        exit={{clipPath: 'inset(0 100% 0 0)', backgroundColor: palette?.accent}}
                    >{data?.team}</motion.p>
                </motion.div>
            </motion.div>
        );
    }
}

export const InterviewCustom = ({ config }) => {

    const data = config?.customInterviewData;
    const palette = config?.palette?.palette;

    if (!data) return <></>;

    switch ( config?.style ) {
        case "k1": return (
            <motion.div
                className="k1_interview_custom"
                style={{left: config?.buttons?.interviewCustom?.left, top: config?.buttons?.interviewCustom?.top}}
                initial={{ rotate: 360, width: "101px", backgroundColor: "#ea324d"}}
                animate={{
                    rotate: [360, 360, 0, 0],
                    width: ["101px", "101px", "101px", "600px"],
                    borderRadius: ["50%", "50%", "0%", "0%"],
                    backgroundColor: "#191921"
                }}
                exit={{
                    rotate: [0, 0, 360, 360],
                    opacity: [1, 1, 1, 0],
                    width: ["600px", "101px"],
                    backgroundColor: "#ea324d",
                    borderRadius: ["0%", "0%", "0%", "50%"],
                    transition: { delay: 0.4}}}
                transition={{ duration: 1, times: [0, 0, 0.5, 1] }}
            >
                <motion.p
                    initial={{clipPath: 'inset(0 100% 0 0)', backgroundColor: "#ea324d"}}
                    animate={{clipPath: 'inset(0 0% 0 0)', backgroundColor: "#191921", transition: {duration: 0.3, delay: 1}}}
                    exit={{clipPath: 'inset(0 100% 0 0)', backgroundColor: "#ea324d", transition: {delay: 0.2}}}
                >{data?.name}</motion.p>
                <motion.span
                    initial={{clipPath: 'inset(0 100% 0 0)', backgroundColor: "#191921"}}
                    animate={{clipPath: 'inset(0 0% 0 0)', backgroundColor: "#ea324d", transition: {duration: 0.3, delay: 1.2}}}
                    exit={{clipPath: 'inset(0 100% 0 0)', backgroundColor: "#191921"}}
                >{data?.description}</motion.span>
            </motion.div>
        )
        case "v2": return (
            <motion.div
                className="k1_interview_custom"
                style={{left: config?.buttons?.interviewCustom?.left, top: config?.buttons?.interviewCustom?.top}}
                initial={{ rotate: 360, width: "101px", backgroundColor: palette?.accent}}
                animate={{
                    rotate: [360, 360, 0, 0],
                    width: ["101px", "101px", "101px", "600px"],
                    borderRadius: ["50%", "50%", "0%", "0%"],
                    backgroundColor: palette?.primary
                }}
                exit={{
                    rotate: [0, 0, 360, 360],
                    opacity: [1, 1, 1, 0],
                    width: ["600px", "101px"],
                    backgroundColor: palette?.accent,
                    borderRadius: ["0%", "0%", "0%", "50%"],
                    transition: { delay: 0.4}}}
                transition={{ duration: 1, times: [0, 0, 0.5, 1] }}
            >
                <motion.p
                    initial={{clipPath: 'inset(0 100% 0 0)', backgroundColor: palette?.secondary}}
                    animate={{clipPath: 'inset(0 0% 0 0)', backgroundColor: palette?.primary, transition: {duration: 0.3, delay: 1}}}
                    exit={{clipPath: 'inset(0 100% 0 0)', backgroundColor: palette?.secondary, transition: {delay: 0.2}}}
                >{data?.name}</motion.p>
                <motion.span
                    initial={{clipPath: 'inset(0 100% 0 0)', backgroundColor: palette?.primary}}
                    animate={{clipPath: 'inset(0 0% 0 0)', backgroundColor: palette?.secondary, transition: {duration: 0.3, delay: 1.2}}}
                    exit={{clipPath: 'inset(0 100% 0 0)', backgroundColor: palette?.primary}}
                >{data?.description}</motion.span>
            </motion.div>
        );
    }
}