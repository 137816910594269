import useAuth from "./useAuth";
import useAxiosPrivate from "./useAxiosPrivate";

const useSetRaceKey = () => {
    const axiosPrivate = useAxiosPrivate();
    const { auth } = useAuth();

    const set = async (apiKey, isDummy = false, dummyData = {}) => {
        console.log(dummyData);
        const payload = { userId: auth.userId, apiKey: apiKey };
        if (isDummy) {
            payload.dummyRaceCompetitorCount = dummyData?.count;
            payload.dummyRaceSidecars = dummyData?.sidecars;
        }
        await axiosPrivate.post('/setApiKey', payload);
    }
    return set;
}

export default useSetRaceKey;