import axios from "axios";
import { getRaceBackendAddress } from "../Functions";

//const BASE_URL = "https://1289-2a03-ec00-b1a0-24ca-79e0-a277-4176-3822.ngrok-free.app";

export default axios.create({
    baseURL: getRaceBackendAddress()
});

export const axiosPrivate = axios.create({
    baseURL: getRaceBackendAddress(),
    headers: {
        'Content-Type': 'application/json'
    },
    withCredentials: true
});