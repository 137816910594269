import VerticalScoreboard from "../../Graphics/VerticalScoreboard/VerticalScoreboard"
import WeatherWidget from "../../Graphics/WeatherWidget/WeatherWidget";
import HorizontalScoreboard from "../../Graphics/HorizontalScoreboard/HorizontalScoreboard";
import LeaderWidget from "../../Graphics/LeaderWidget/LeaderWidget";
import FastestLapWidget from "../../Graphics/FastestLapWidget/FastestLapWidget";
import TimerWidget from "../../Graphics/TimerWidget/TimerWidget";
import RaceWinnerWidget from "../../Graphics/RaceWinner/RaceWinner";
import ReplayWidget from "../../Graphics/Replay/ReplayWidget";
import ResultsByClass from "../../Graphics/ResultsByClass/ResultsByClass";
import StartGridGraphic from "../../Graphics/StartGrid/StartGridGraphic";
import Battles from "../../Graphics/Battles/Battles";
import Announcement from "../../Graphics/Announcer/Announcer";
import RaceResults from "../../Graphics/RaceResults/RaceResults";
import Points from "../../Graphics/Points/Points";
import Simulator from "../../Graphics/Simulator/Simulator";

import { InterviewCompetitor, InterviewCustom } from "../../Graphics/Interview/InterviewCompetitor";
import { ScheduleActive, ScheduleFull } from "../../Graphics/Schedule/Schedule";
import { Formation, SafetyCar } from "../../Graphics/FormationAndSafety/Widgets";

import { CSSTransition } from "react-transition-group";
import React, { useState, useEffect } from "react";
import io from "socket.io-client";
import { useParams } from "react-router-dom";

import "./Graphics.css"

import { getRaceBackendAddress } from "../../Functions";

import { AnimatePresence } from "framer-motion";

const transitionConfig = {
    timeout: 400,
    classNames: "transition",
    unmountOnExit: true
}

let socket;
const Graphics = React.memo(() => {

    const { hash } = useParams();
    if (!socket) {
        socket = io.connect(getRaceBackendAddress(), {
            query: {
                userId: decodeURIComponent(hash),
                userName: "",
                userType: "Graphics Panel",
            }
        });
    }


    const [data, setData] = useState({});
    const [config, setConfig] = useState({});
    const [generalImage, setGeneralImage] = useState("");
    const raceFlag = data?.data?.raceDetails?.flag;

    useEffect(() => {
        socket.on("getApi", setData);
        socket.on("getConfig", (data) => {
            setConfig(data);
        });
    }, [socket]);

    return (

        <div className="app__graphics">
            {
                /* Simulator */
                <AnimatePresence>
                    {
                        config && config?.buttons?.simulator?.show &&
                        <Simulator config={config} socket={socket} raceData={data?.data}/>
                    }
                </AnimatePresence>
            }
            {
                /* Schedule full */
                <AnimatePresence>
                    {
                        config && config?.buttons?.scheduleAll?.show &&
                        <ScheduleFull config={config} socket={socket} raceData={data?.data?.raceDetails}/>
                    }
                </AnimatePresence>
            }
            {
                /* Formation active */
                <AnimatePresence>
                    {
                        config && config?.buttons?.Formation?.show &&
                        <Formation config={config} />
                    }
                </AnimatePresence>
            }
            {
                /* SafetyCar active */
                <AnimatePresence>
                    {
                        config && config?.buttons?.safetyCar?.show &&
                        <SafetyCar config={config} />
                    }
                </AnimatePresence>
            }
            {
                /* Schedule active */
                <AnimatePresence>
                    {
                        config && config?.buttons?.scheduleNow?.show &&
                        <ScheduleActive config={config} />
                    }
                </AnimatePresence>
            }
            {
                /* Points */
                <AnimatePresence>
                    {
                        config && config?.buttons?.pointStandings?.show &&
                        <Points config={config} socket={socket} />
                    }
                </AnimatePresence>
            }
            {
                /* Interviews */
                <AnimatePresence>
                    {
                        config && config?.buttons?.interviewCompetitor?.show &&
                        <InterviewCompetitor config={config} />
                    }
                </AnimatePresence>
            }
                        {
                /* Interviews */
                <AnimatePresence>
                    {
                        config && config?.buttons?.interviewCustom?.show &&
                        <InterviewCustom config={config} />
                    }
                </AnimatePresence>
            }
            {
                /* Weather board start */
                <AnimatePresence>
                    {
                        config && config?.buttons?.weather?.show &&
                        <WeatherWidget config={config} />
                    }
                </AnimatePresence>
            }
            {
                /* Battles start */
                <AnimatePresence>
                    {
                        config && config?.buttons?.battles?.show &&
                        <Battles config={config} data={data} />
                    }
                </AnimatePresence>
            }
             {
                /* Results start */
                <AnimatePresence>
                    {
                        config && config?.buttons?.raceResults?.show &&
                        <RaceResults config={config} data={data} />
                    }
                </AnimatePresence>
            }
            {
                /* Announcer start */
                <AnimatePresence>
                    {
                        config && config?.buttons?.announcer?.show &&
                        <Announcement config={config} />
                    }
                </AnimatePresence>
            }
            {
                /* Start grid board start */
                <AnimatePresence>
                    {
                        config && config?.buttons?.startGrid?.show &&
                        <StartGridGraphic data={data} config={config} />
                    }
                </AnimatePresence>
            }
            {
                /* Vertical scoreboard */
                <AnimatePresence>
                    {
                        config && config?.buttons?.verticalScoreboard?.show &&
                        raceFlag && raceFlag !== "None" &&
                        <VerticalScoreboard data={data} config={config} image={generalImage}/>
                    }
                </AnimatePresence>


            }
            {
                /* Horizontal scoreboard start */
                <CSSTransition
                    in={config?.buttons?.horizontalScoreboard?.show}
                    {...transitionConfig}
                    >
                    {
                        (raceFlag && raceFlag !== "None") ?
                        <HorizontalScoreboard data={data} config={config} image={generalImage}/>
                        :
                        <></>
                    }
                </CSSTransition>
            }
            {
                /* ResultsByClass scoreboard start */
                <CSSTransition
                    in={config?.buttons?.resultsByClass.show}
                    {...transitionConfig}
                    >
                    <ResultsByClass data={data} config={config} image={generalImage}/>
                </CSSTransition>
            }
            {
                /* LeaderWidget start */
                <CSSTransition
                    in={config?.buttons?.raceLeader?.show}
                    {...transitionConfig}
                    >
                    <LeaderWidget data={data?.data?.raceLeader} config={config} fromSecond={data?.data?.topTenCompetitors[1]?.diff}/>
                </CSSTransition>
            }
            {
                /* FastestLapWidget start */
                <CSSTransition
                    in={config?.buttons?.fastestLap?.show}
                    {...transitionConfig}
                    >
                    <FastestLapWidget data={data?.data?.competitorWithFastestLap} config={config}/>
                </CSSTransition>
            }
            {
                /* TimerWidget start */
                <AnimatePresence>
                    {config?.buttons?.timer?.show &&
                        <TimerWidget data={data} config={config}/>
                    }
                </AnimatePresence>
            }
            {
                /* RaceWinnerWidget start */
                <CSSTransition
                    in={config?.buttons?.winner?.show}
                    {...transitionConfig}
                    >
                    <RaceWinnerWidget data={data} config={config} winner={data?.data?.raceLeader}/>
                </CSSTransition>
            }
            {
                /* ReplayWidget start */
                <CSSTransition
                    in={config?.buttons?.replay?.show}
                    {...transitionConfig}
                    >
                    <ReplayWidget config={config} />
                </CSSTransition>
            }

        </div>
    );
});
export default Graphics;