import "./Home.scss";
import {
    motion,
    useAnimation
}
from "framer-motion"
import { useInView } from 'react-intersection-observer';
import { useEffect, useState, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';
import Slider from "react-slick";

// icons
import { FaFacebook, FaCheck } from "react-icons/fa6";
import { GiHamburgerMenu } from "react-icons/gi";
import { FaFacebookSquare } from "react-icons/fa";
import { SiGmail } from "react-icons/si";
import { MdEmail } from "react-icons/md";
import { IoIosArrowDown, IoIosArrowUp, IoIosRemoveCircle } from "react-icons/io";

const scrollTo = id => {
    document.getElementById(id).scrollIntoView({ behavior: 'smooth' });
};

const Navbar = ({isTabletOrMobile}) => {
    const [open, isOpen] = useState(false);
    const navigate = useNavigate();
    return (
        <>
        <div className="rg-navbar">
            <div className="flex items-center justify-center py-2 bg-[#ebae21] text-[#020817] w-[calc(100%+20px)]">
                <p>This website is currently being developed</p>
            </div>
            <div className="rg-navbar-inner">
                <div className="rg-navbar-logo flex items-center gap-3">
                    <img src="/logo/RGlogo-new.png" alt="RG Logo" />
                </div>
                {
                    isTabletOrMobile ?
                    <div className="rg-navbar-hamburger" onClick={() => isOpen(prev => !prev)}>
                        <GiHamburgerMenu size={"30px"} color="white"/>
                    </div>
                    :
                    <>
                        <ul>
                            <li onClick={() => scrollTo('title')}>Home</li>
                            {/* <li onClick={() => scrollTo('about')}>About us</li> */}
                            <li onClick={() => scrollTo('info')}>Our product</li>
                            <li onClick={() => scrollTo('prices')}>Pricing</li>
                            {/* <li onClick={() => scrollTo('help')}>Help</li> */}
                            <hr className="w-[1px] h-[32px] mt-[6px] bg-[#3f3f42] border-none mx-2"/>
                            <li className="w-10 h-10">
                                <FaFacebookSquare size={"100%"} color="#3ac2f8"/>
                            </li>
                            <li className="w-10 h-10" title="Gmail">
                                <SiGmail size={"100%"} color="#ed4337"/>
                            </li>
                        </ul>
                        <div className="flex gap-3">
                            <button onClick={() => navigate("/login")}>Sign in</button>
                            <button onClick={() => navigate("/register")}>Sign up</button>
                        </div>
                    </>
                }
            </div>
        </div>
        {
            open &&
            <div className="w-full h-full z-20 backdrop-blur-sm fixed top-[114px] left-0">
                <div className="w-[80%] h-full bg-[#080300bd] ml-[20%]">

                </div>
                {/* <ul>
                    <li onClick={() => scrollTo('about')}>About us</li>
                    <li onClick={() => scrollTo('graphicsInfo')}>Our product</li>
                    <li>Pricing</li>
                    <li>Help</li>
                    <li>Live timing</li>
                </ul> */}
            </div>
        }
        </>
    );
};

const useIntersectionObserver = (callback, options) => {
    const [isIntersecting, setIsIntersecting] = useState(false);
    const ref = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(([entry]) => {
            if (entry.isIntersecting) {
                setIsIntersecting(true);
                callback();
                observer.disconnect();
            }
        }, options);

        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => {
            if (ref.current) {
                observer.unobserve(ref.current);
            }
        };
    }, [callback, options]);

    return ref;
};

const Title = () => {
    return (
        <div id="title" className="rg-title">
            <div className="rg-title-inner">
                <span className="text-[#3cfffc]">The easiest way</span>
                <h3>To the world of live TV graphics.</h3>
                <p className="!text-[1.1rem] font-[500] mt-1">Get started with our lastest graphics panel right now! 🚀</p>
                {/* <button className="mt-[60px]">Buy now</button> */}
            </div>
        </div>
    );
};

const Interclude = ({type, reverse = false}) => {

    switch (type) {
        case "v": {
            return (
                <div
                    className="w-full h-fit"
                    style={{background: reverse ? "#f5f6f8" : "#020817"}}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 110">
                        <path d="M 0 0 L 1920 0 L 960 110 Z" fill={reverse ? "#020817" : "#f5f6f8"}/>
                    </svg>
                </div>
            );
        }
        case "a": {
            return (
                <div className="w-full h-fit bg-[#020817]">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 110" />
                </div>
            );
        }
        case "d": {
            return (
                <div className="w-full h-fit bg-[#020817]">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" fill="#f5f5f7"><path d="m0 4 86.6 23.1c42.1 11.2 85.5 16.9 129 16.9h29.7c73 0 129.8 17.1 127.3 16.5 83.5 22 171.3 22 254.8 0 123.2-32.4 162.9-.6 286-33.4L1000 4V0H0v4Z"></path></svg>
                </div>
            );
        }
        case "g": {
            return (
                <div className="w-full h-fit bg-[#fff]">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 50" fill="#020917"><path d="M0 0v47.6l5-2c1 0 11 3 12 0 2 3 6-3 6 0 0-4 12 3 12 0 0 3 15-1 17 0 2-2 5-1 6 0 0-2 6 2 6 0s2 4 4 0c5 2 12-3 16 0 2-2 4-3 4 0 0 2 6-1 6 0 1 4 15-2 17 0h7c0 1 3-3 6 0h17c2 2 3 1 6 0h6c1-2 21-1 24 0 2 1 4 2 6 0 0-1 22 4 24 0 0 0 5-3 5 0 2-2 10 2 12 0 2 2 6 1 6 0 2 3 4-2 6 0 1 0 25-2 25 1l10-1c3 1 6 6 7 0 1 5 4-2 6 0 2-2 4 3 5 0h12c6 1 36 2 36 0 0 2 3 0 6 0h6c5-2 7 4 11 0 2 0 15 2 17 0h13c3-4 5 1 7 0h29c0-3 6 0 6 0h5c0 2 16-1 18 0 1 4 9-1 12 0s6-2 6 0c8-2 3 4 13 0h10c3 4 19 1 19 0 2 0 21 1 23-1 1 4 3-1 6 1 1 2 11-1 12-1 3 3 9 0 12 1 3-4 6 1 6 0h6c0-3 5 1 6-1 0 3 2 1 4 1 3 4 10-1 13 0 3-2 6-1 6 0 2 2 2 0 6 0 1-2 6 2 6 0 2 0 4 5 6 0h18c2 3 4 1 6 0l6-1c3 2 12 3 17 1 14 3 18 1 24 0 2-1 3 3 5 0 6 2 10-1 16 0 1 3 6 0 9 0 0-2 3 2 5 0 6-6 8 7 13 0 0-2 5 2 5 0 3 3 10 0 10 0 1 2 5-2 8 0 3-1 8 3 12 0h6c2 1 10 4 12 0h6c1-1 5 2 6 0 1 2 4-1 6 0 0-2 5 3 6-1 2 1 6 5 5 1 1 1 3-2 6 0 2-1 5 3 6 0 0 1 6 2 6 0 2 3 4-4 6 0 0-2 3 2 6 0 3 0 6 3 6 0 5 3 8-1 13 0 3-4 6 1 6 0h5c0-1 9 2 12 0 1-1 9 3 11 0h6c2 2 4 4 7 0 3 2 5-4 5-1 10 4 15-2 18 2 0-1 6 2 6-2 0 0 6-2 6 1 1 6 12 2 12 0 1 3 4-3 7 2 2-2 5 2 5 0 1 5 4-5 6 0 2-1 4 2 6 0 1 3 1 0 5 0V0H0Z"></path></svg>
                </div>
            );
        }
        case "l": {
            return (
                <div className="w-full h-fit bg-[#020817]">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 3840 560" shapeRendering="geometricPrecision" textRendering="geometricPrecision">
                        <defs><linearGradient id="eW51mlOxUuA2-fill" x1="0" y1="251.49" x2="1920" y2="251.49" spreadMethod="pad" gradientUnits="userSpaceOnUse" gradientTransform="translate(0 0)"><stop id="eW51mlOxUuA2-fill-0" offset="0%" stop-color="#8504f5"/><stop id="eW51mlOxUuA2-fill-1" offset="100%" stop-color="#007bf5"/></linearGradient><radialGradient id="eW51mlOxUuA3-fill" cx="0" cy="0" r="829.48" spreadMethod="pad" gradientUnits="userSpaceOnUse" gradientTransform="translate(950.81 180.06)"><stop id="eW51mlOxUuA3-fill-0" offset="0%" stop-color="#f3728e"/><stop id="eW51mlOxUuA3-fill-1" offset="100%" stop-color="#f37cbc"/></radialGradient></defs>
                        <path id="eW51mlOxUuA2" d="M 0 163.3468737 L 0 374.8291521 C 205.62 300.42846947500004 375.81717530000003 270.503343925 758.7971753 265.70902715 C 1371.9371752999998 258.021409725 1472.44323815 203.99576015 1790.3832381500001 178.3634036 C 2246.88323815 141.565200225 2318.3443342 290.97653867500003 2823.49359085 261.67402715000003 C 3322.85958285 242.1484421 3373.62 379.964610025 3840 379.964610025 L 3840 194.546486275 C 3599.096576925 179.61925340000002 3410.282488975 223.2436746 3091.642488975 158.28539195 C 2882.582488975 115.64835894999999 2613.1467634 98.78456685 2289.8667634 112.2065628 C 1738.5867633999999 135.102294425 1679.43615505 117.34812182499999 1150.93615505 122.15288352500001 C 622.43615505 126.95764430000001 457.682996 128.3061791 0 163.3468737 Z" fill="url(#eW51mlOxUuA2-fill)"/>
                        <path id="eW51mlOxUuA3" d="M 0 395.88279124999997 L 0 520.17 C 439.41263915 499.940181925 753.98891525 412.262107325 1105.48310415 395.13579125 C 1704.5588428 355.78687235 1720.8645915 473.55079035 2224.6236745 436.4897047 C 2623.5617387 385.346992075 2801.2514115500003 360.992995575 3021.85141155 372.1901781 C 3242.45141155 383.38736155 3476 472.55784389999997 3840 472.55784389999997 L 3840 289.9952298 C 3558.2772884 249.50515085 3309.77008035 332.87885432499996 2901.00181775 284.498592625 C 2548.8509477499997 243.46222782499999 2401.6682138 196.955724875 1972.8643206499999 227.619302925 C 1577.7218768 244.67361405 1324.6985467 292.07481597500004 1059.5585467 333.0510726 C 567.8585467 409.06030605 521.17291705 314.99405282500004 0 395.88279124999997 Z" fill="url(#eW51mlOxUuA3-fill)"/>
                    </svg>
                </div>
            );
        }
    }

}

const Textarea = () => {

    const settings = {
        className: "slider2",
        arrows: true,
        dots: true,
        infinite: true,
        autoplaySpeed: 3000,
        slidesToScroll: 1,
        slidesToShow: 1
    };

    return (
        <>
            <Clients />
            <div className="w-full h-fit max-w-[100vw] bg-gradient-to-b from-[#fff] to-[#f5f5f7] text-[#13141a] px-[10px] pt-[40px] pb-10 overflow-hidden mb-3">
                <header className="w-full flex flex-col items-center">
                    <h2 className="font-monsterrat font-[800] text-[3.1rem] text-[#3a4258]">One panel -</h2>
                    <span className="inline-block h-[106px] w-[500px] relative">
                        <svg className="relative z-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 504 106" style={{transform: 'scaleX(0.90) scaleY(0.83)', marginLeft: '-20px', marginTop: '-8px'}}>
                            <path fill="none" stroke="#003891" strokeLinecap="round" strokeWidth={"43"} d="M1106,1813.61193 C1391.48757,1788.3249 1542.09692,1785.22818 1557.82804,1804.32178 C1581.42472,1832.96217 1297.6495,1822.13368 1191.16891,1835.26224 C1084.68832,1848.39079 1016.09991,1866.56524 1566,1841.45052"
                                transform="translate(-1084 -1770)" />
                        </svg>
                        <span className="text-white font-monsterrat font-[800] text-[3.1rem] z-10 absolute top-0 left-0 text-center w-full">many designs!</span>
                    </span>
                    <span className="text-[#3a4258] font-monsterrat font-[700] text-center">Choose between different graphics designs to best suit your look*</span>
                </header>
                <div className="w-full flex justify-center">
                    <div className="w-3/5 h-[500px]">
                        <Slider {...settings}>
                            <div className="w-full h-[500px] flex justify-center items-center">
                                <img src="/home/grid.png" className="h-full max-h-full w-auto mx-auto shadow-md bg-[#020917] p-2"/>
                            </div>
                            <div className="w-full h-[500px] flex justify-center items-center">
                                <img src="/home/vert.png" className="h-full max-h-full w-auto mx-auto shadow-md bg-[#020917] p-2"/>
                            </div>
                            <div className="w-full h-[500px] flex justify-center items-center">
                                <img src="/home/battle.png" className="h-full max-h-full w-auto mx-auto shadow-md bg-[#020917] p-2"/>
                            </div>
                            <div className="w-full h-[500px] flex justify-center items-center">
                                <img src="/home/hor.png" className="h-full max-h-full w-auto mx-auto shadow-md bg-[#020917] p-2"/>
                            </div>
                        </Slider>
                    </div>
                </div>
                <p className="text-center text-[.8rem] mt-10">* Designs can differ from show due to constant updates.</p>
            </div>
        </>
    );
}

const About = () => {
    const animation = useAnimation();
    const [ref, inView] = useInView({
        threshold: 0.1,
    });

    useEffect(() => {
        if (inView) {
            animation.start({
                opacity: 1,
                transition: { duration: 1.5, delay: 0.2 },
            });
        } else {
            animation.start({ opacity: 0 });
        }
    }, [animation, inView]);
    return (
        <motion.div
            id="about"
            className="rg-about mb-3"
        >
            <span>About us</span>
            <motion.div
                className="rg-about-inner"
                animate={animation}
                initial={{ opacity: 0 }}
                ref={ref}
            >
                <p>A company with more than 17 years of experience.</p>
                <h2>
                    We work in providing race timing service as well as serve as the official reseller of MyLaps in technical sports.
                    We specialize in technical and IT solutions of timing systems.  Our latest solution is the development of data and graphics servers for streaming race results in video graphics - RaceGraphics.
                </h2>
            </motion.div>
        </motion.div>
    );
}

const Clients = () => {

    const ImageCarousel = () => {
        const settings = {
            className: "slider",
            arrows: false,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 3000,
            slidesToScroll: 1,
            slidesToShow: 5,
            responsive: [
                {
                    breakpoint: 1124, // For screens smaller than 1024px
                    settings: {
                        slidesToShow: 3,
                    },
                },
                {
                    breakpoint: 850, // For screens smaller than 768px
                    settings: {
                        slidesToShow: 2,
                    },
                },
                {
                    breakpoint: 480, // For screens smaller than 480px
                    settings: {
                        slidesToShow: 1,
                    },
                },
            ],
        };
        const images = importAll(
            require.context("../../../public/home/clients", false, /\.(png|jpe?g|svg)$/)
        );
        return (
            <div className="w-full h-[100px] flex items-center justify-center outer-ring">
                <div className="w-3/5 h-[100px] inner-ring">
                    <Slider {...settings}>
                    {images.map((image, index) => (
                        <div className="w-fit h-[70%] clients-images justify-center" key={`${index}_slider_img`}>
                            <img src={image} alt={`Image ${index}`} className="grayscale"/>
                        </div>
                    ))}
                    </Slider>
                </div>
            </div>
        );
    };

    function importAll(r) {
        return r.keys().map(r);
    };

    return (
        <div className="rg-clients">
            <span>Trusted by our customers</span>
            <ImageCarousel />
        </div>
    );
}

const Showcase = () => {

    const links = [
        "https://www.youtube.com/watch?v=0x1sdl5Z9d4&ab_channel=RallycrossLV",
        "https://www.youtube.com/watch?v=mwDNz08VePo&ab_channel=RotaxMaxLatvia",
        "https://www.youtube.com/watch?v=kAjredUO5nM&t=4817s&ab_channel=Best4SportTV"
    ];

    return (
        <div className="rg-showcase mt-3">
            <span>Showcase</span>
            <p>See us in action! 🎬</p>
            <div className="rg-showcase-inner">
            {
                links.map((item, index) =>
                    <div className="rg-showcase-item" key={`rg_showcase_${index}`}>
                        <iframe
                            className="shadow-2xl"
                            width="560"
                            height="315"
                            src={`https://www.youtube.com/embed/${item.split('=')[1].split('&')[0]}`}
                            title={`YouTube video player ${index}`}
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        ></iframe>
                    </div>
                )
            }
            </div>
        </div>
    );
}

const Prices = () => {

    const plans = [
        {
            name: "1x access",
            cost: 350,
            amount: 1,
            text: "For those who want to try our products.",
            popular: 'Starter',
            bg: "white",
            col: "#232f44",
            fill: "whitesmoke",
            btn: "Try with starter"
        },
        {
            name: "5x access",
            cost: 1500,
            amount: 5,
            text: "Our package designed for smaller venues.",
            popular: 'For small events',
            bg: "#344469",
            col: "white",
            fill: "rgb(57, 75, 117)",
            btn: "Start with 5"
        },
        {
            name: "10x access",
            cost: 2500,
            amount: 10,
            text: "Made for larger events and should cover the whole season!",
            popular: 'Most popular',
            bg: "#232f44",
            col: "white",
            fill: "rgb(44, 60, 87)",
            btn: "Select popular"
        },
        {
            name: "20x access",
            cost: 4000,
            amount: 20,
            text: "Our biggest package with the best price.",
            popular: 'Pro',
            bg: "#13141a",
            col: "white",
            fill: "rgb(32,32,32)",
            btn: "Start with Pro"
        }
    ];

    const calculateSavings = (cost, amount) => {
        const singleAccessCostPerUse = plans[0].cost / plans[0].amount;
        const currentCostPerUse = cost / amount;
        const savingsPercentage = ((singleAccessCostPerUse - currentCostPerUse) / singleAccessCostPerUse) * 100;
        return savingsPercentage.toFixed(0);
    };

    return (
        <div className="rg-prices" id="prices">
            <div className=" w-full py-10 flex flex-col items-center">
            <span>Prices</span>
            <p className="inline-block h-[106px] w-[500px] relative !mt-[-20px]">
                <svg className="relative z-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 504 106" style={{transform: 'scaleX(0.90) scaleY(0.83)', marginLeft: '-20px', marginTop: '-8px'}}>
                    <path fill="none" stroke="#60f" strokeLinecap="round" strokeWidth={"43"} d="M1106,1813.61193 C1391.48757,1788.3249 1542.09692,1785.22818 1557.82804,1804.32178 C1581.42472,1832.96217 1297.6495,1822.13368 1191.16891,1835.26224 C1084.68832,1848.39079 1016.09991,1866.56524 1566,1841.45052"
                        transform="translate(-1084 -1770)" />
                </svg>
                <p className="text-white font-monsterrat font-[800] !text-[1.3rem] z-10 absolute top-6 left-0 text-center w-full">Browse our graphics pricing plans.</p>
            </p>
            <p className="!text-[#13141a] !text-[.8rem]">* prices are adjusted for 2025-2026 season and are calculated without VAT</p>
            <div className="rg-prices-inner">
            {
                plans.map((plan, index) =>
                    <div
                        className={`rg-prices-item shadow-2xl`}
                        key={`rg_prices_${index}`}
                        style={{background: plan.bg}}
                    >
                        <div
                            className={`p-[10px] w-full h-full rounded-[20px] `}
                            style={{background: `transparent url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="5" height="5" viewBox="0 0 5 5"><circle cx="1" cy="1" r="1" fill="${plan.fill}" /></svg>') repeat`}}
                        >
                            <p
                                className={`p-3 border-[1px] rounded-xl w-fit !text-[0.9rem]`}
                                style={{ borderColor: plan.col, color: plan.col, background: plan.bg }}
                            >{plan.popular}</p>
                            {index !== 0 && (
                                <h4>{`Save ~${calculateSavings(plan.cost, plan.amount)}%`}</h4>
                            )}
                            <p className="!text-[.8rem] w-fit text-start min-h-[40px] max-w-[80%]" style={{color: plan.col}}>{plan.text}</p>
                            <h2 className="text-start !text-[1.6rem] font-monsterrat font-[700]" style={{color: plan.col}}>{plan.name}</h2>
                            <p className="!text-[.75rem] w-fit text-start" style={{color: plan.col}}>STARTING AT</p>
                            <h3 className="text-start !text-[2.5rem] font-monsterrat !font-[800]" style={{color: plan.col}}>{`€${plan.cost}*`}</h3>
                            <p className="text-start !text-[.8rem] mb-3" style={{color: plan.col}}>{`*Price per 24h access - €${plan.cost / plan.amount}`}</p>
                            {/* <button
                            style={{
                                background: plan.col !== "white" ? "white" : "#166bff",
                                border: `1px solid ${plan.col !== "white" ? "#232f44" : "none"}`,
                                color: plan.col !== "white" ? "#13141a" : "white"
                            }}>Select Plan</button> */}
                            <div className="flex items-center gap-3 mt-5" style={{color: plan.col === "white" ? "white" : "#232f44"}}><FaCheck color="#44ba38"/>Personal graphics account</div>
                            <div className="flex items-center gap-3 mt-1" style={{color: plan.col === "white" ? "white" : "#232f44"}}><FaCheck color="#44ba38"/>All 3 design options</div>
                            <div className="flex items-center gap-3 mt-1" style={{color: plan.col === "white" ? "white" : "#232f44"}}><IoIosRemoveCircle color="#f55353" size={"20px"}/>Live Timing not included</div>
                        </div>
                    </div>
                )
            }
            </div>
            </div>
        </div>
    );
}

export const Footer = ({bg}) => {
    return (
        <div className="rg-footer" style={{background: bg || "#13141a"}}>
            <div className="w-4/5 py-3 flex items-center justify-center gap-3">
                <img src="/logo/RGlogo-new.png" alt="RG Logo" className="w-[40px] h-auto"/>
                <p className="text-white">RACEGRAPHICS</p>
            </div>
            <div className="w-4/5 py-3 flex justify-evenly !text-[#707497]">
                <div className="w-fit">
                    <p className="mb-5">Contact us</p>
                    <div className="flex items-center gap-3">
                        <MdEmail color="#707497" size={"20px"}/>
                        <p>racegraphicseu@gmail.com</p>
                    </div>
                    <div className="flex items-center gap-3">
                        <FaFacebook color="#707497" size={"20px"} />
                        <p>Our facebook page</p>
                    </div>
                </div>
                <div className="w-fit !text-[#707497]">
                    <p className="mb-5">Navigation</p>
                    <ul className="list-disc">
                        <li>Home</li>
                        <li>Showcase</li>
                        <li>Services</li>
                        <li>Pricing</li>
                        <li>FAQ</li>
                        <li>About</li>
                        <li>RaceOnline</li>
                    </ul>
                </div>
            </div>
            <div className="w-4/5 pt-5 mt-5 flex justify-between border-t-[1px] border-t-[#252732]">
                <h6>RaceGraphics™ 2024. All rights reserved.</h6>
                <h6>Developed by <span>ROBERTS KALVE</span></h6>
            </div>
        </div>
    );
}

const Panel = () => {
    return (
        <div className="w-full h-fit p-[10px] bg-[#020917] overflow-hidden text-white flex">
            <div className="w-2/5 flex flex-col justify-center p-3">
            <span className="inline-block h-[106px] w-[500px] relative">
                <svg className="relative z-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 504 106" style={{transform: 'scaleX(0.95) scaleY(0.87)', marginLeft: '-20px', marginTop: '-8px'}}>
                    <path fill="none" stroke="#5d60ef" strokeLinecap="round" strokeWidth={"43"} d="M1106,1813.61193 C1391.48757,1788.3249 1542.09692,1785.22818 1557.82804,1804.32178 C1581.42472,1832.96217 1297.6495,1822.13368 1191.16891,1835.26224 C1084.68832,1848.39079 1016.09991,1866.56524 1566,1841.45052"
                        transform="translate(-1084 -1770)" />
                </svg>
                <span className="text-white font-monsterrat font-[800] text-[3.1rem] z-10 absolute top-2 left-0 text-center w-full">Next generation</span>
            </span>
            <span className="text-[3.1rem] font-monsterrat font-[800] ml-[20px] mt-[-25px]">Control panel</span>
                <p className="text-[#dcdcdc] ml-[20px] mt-3 text-[1.2rem] font-monsterrat">Our 3rd generation graphics panel with more features than ever, designed in collaboration with streamers to make managing graphics in your stream a breeze.</p>
            </div>
            <div className="w-3/5">
                <img className="w-full h-auto tilt p-1 bg-white min-h-full"  src="/home/panel.png" />
            </div>
        </div>
    );
}

const FAQ_ITEM = (i, indx) => {

    const [open, setOpen] = useState(false);

    return (
        <div className="mt-2 py-3" id="help" style={{borderBottom: open ? "1px solid #0bc272" : "1px solid #282b38"}}>
            <div
                key={`faq_${indx}`}
                className="text-white w-full flex items-center justify-between pb-3 text-[1.2rem] uppercase">
                    <p>{i.i.q}</p>
                    {
                        open ?
                        <IoIosArrowUp size={"20px"} className="cursor-pointer" onClick={() => setOpen(prev => !prev)}/>
                        :
                        <IoIosArrowDown size={"20px"} className="cursor-pointer" onClick={() => setOpen(prev => !prev)}/>
                    }
            </div>
            <div className={`${open ? 'max-h-[500px]' : 'max-h-0'} overflow-hidden transition-max-height duration-300 ease-in-out`}>
                <div className="pb-2 text-[#cacaca]">{i.i.a}</div>
            </div>
        </div>
    );
}

const FAQ = () => {

    const q = [
        {q: 'Are your products only usable with ORBITS 5 from MyLaps?', a: 'Ye'},
        {q: 'For how long can I use the graphics panel?', a: 'One access token can be redeemed for 24hr uninterrupted access. You can also extend this duration for 24hrs at anytime by activating another token'},
        {q: 'Placeholder', a: 'Placeholder'},
        {q: 'Placeholder', a: 'Placeholder'},
        {q: 'Placeholder', a: 'Placeholder'},
        {q: 'Placeholder', a: 'Placeholder'},
        {q: 'Placeholder', a: 'Placeholder'},
        {q: 'Placeholder', a: 'Placeholder'},
    ];

    return (
        <div className="faq w-full py-20 bg-[#020817] text-white flex justify-center">
            <div className="w-2/5 px-5 text-[2.3rem] relative">
                <svg width="336" height="142" viewBox="0 0 336 142" style={{transform: 'scaleX(0.7) scaleY(0.7)'}} fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.96711 24.7938C-8.97503 45.1389 4.9948 80.0796 14.0975 95.0067C23.5451 115.463 58.1249 123.36 102.709 117.674C141.721 112.698 108.283 142 108.283 142C108.283 142 299.996 69.576 194.108 22.0299C113.99 -13.9449 29.1448 -0.637698 7.96711 24.7938Z" fill="#E9F3FF"></path></svg>
                <p className="text-[#8a81ff] !text-[3.5rem] font-monsterrat !font-[800] z-2">Frequently</p>
                <span className="font-monsterrat !font-[800] z-2">asked questions</span>
                <p className="text-[1.1rem] max-w-[370px] text-[#c9c9c9] font-monsterrat z-2">Our products are always improving and growing and we're here to help along the way. If you have additional questions, feel free to reach out.</p>
            </div>
            <div className={`w-2/5`}>
                {q.map((i, indx) =>
                    <FAQ_ITEM i={i} indx={indx} />
                )}
            </div>
        </div>
    );
}

const Services = () => {

    const s = [
        {t: 'On-screen results', d: 'Let your viewers now whats happening on track with modern graphics.'},
        {t: 'Weather monitoring', d: 'Showcase the current weather conditions on track.'},
        {t: 'Schedule', d: 'Use our scheduler to display your daily schedule, current race and also whats up next!'},
        {t: 'Interviews', d: 'Get access to competitor data to quickly display it when the time comes for an interview.'},
        {t: 'Showcase drivers', d: 'Upload pictures from competitors to display then in graphics.'}
    ];

    const lt = [
        {t: 'Live timing', d: 'Use our Live Timing system to access live results of your event from any device.'},
        {t: 'Result history', d: 'Access past results of your event.'}
    ];

    const t = [
        'Easy and fast set-up', 'No extra hardware', 'Instant access', 'Frequent updates', 'Inspired from customer ideas', 'User friendly'
    ];

    return (
        <div className="w-full h-fit text-white bg-[#020817] mb-3" id="info">
            <div className="w-full h-full py-[50px] flex flex-col items-center">
            <p className=" text-[#ffffff] font-monsterrat text-[3.1rem] font-[800]">Our services</p>
            <span className="text-[1.5rem] mb-10 text-center font-monsterrat font-[700]">How we can help <span className="text-[#5b50ff] underline-offset-2 underline">your</span> event grow.</span>
            <div className="flex w-full">
                <div className="w-1/2 my-[20px] flex flex-col items-center">
                    <span className="inline-block h-[106px] w-[500px] relative">
                        <svg className="relative z-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 504 106" style={{transform: 'scaleX(0.7) scaleY(.6)', marginLeft: '-20px', marginTop: '-8px'}}>
                            <path fill="none" stroke="#5b50ff" strokeLinecap="round" strokeWidth={"43"} d="M1106,1813.61193 C1391.48757,1788.3249 1542.09692,1785.22818 1557.82804,1804.32178 C1581.42472,1832.96217 1297.6495,1822.13368 1191.16891,1835.26224 C1084.68832,1848.39079 1016.09991,1866.56524 1566,1841.45052"
                                transform="translate(-1084 -1770)" />
                        </svg>
                        <span className="text-white font-monsterrat font-[700] text-[2.3rem] z-10 absolute top-4 left-0 text-center w-full">Streamers</span>
                    </span>
                    <div className="py-2 w-full flex flex-wrap justify-center gap-3">
                        {
                            s.map((i, indx) =>
                                <div
                                    className="p-4 border-[#1d2b42] bg-[#101827] border-[1px] rounded-xl hover:border-[#5b50ff] font-monsterrat hover:scale-[1.01] max-w-[40%]"
                                    key={`service_${indx}`}><p className="font-[700]">{i.t}</p><span className="text-[#dedede]">{i.d}</span></div>
                            )
                        }
                    </div>
                </div>
                <div className="w-1/2 my-[20px] flex flex-col items-center">
                    <span className="inline-block h-[106px] w-[500px] relative">
                        <svg className="relative z-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 504 106" style={{transform: 'scaleX(0.7) scaleY(0.6)', marginLeft: '-20px', marginTop: '-8px'}}>
                            <path fill="none" stroke="#0bc272" strokeLinecap="round" strokeWidth={"43"} d="M1106,1813.61193 C1391.48757,1788.3249 1542.09692,1785.22818 1557.82804,1804.32178 C1581.42472,1832.96217 1297.6495,1822.13368 1191.16891,1835.26224 C1084.68832,1848.39079 1016.09991,1866.56524 1566,1841.45052"
                                transform="translate(-1084 -1770)" />
                        </svg>
                        <span className="text-white font-monsterrat font-[700] text-[2.3rem] z-10 absolute top-4 left-0 text-center w-full">Organizers</span>
                    </span>
                    <div className="py-2 w-full flex flex-wrap justify-center gap-3">
                        {
                            lt.map((i, indx) =>
                                <div
                                    className="p-4 border-[#1d2b42] bg-[#101827]  border-[1px] rounded-xl hover:border-[#0bc272] font-monsterrat hover:scale-[1.01] max-w-[30%]"
                                    key={`service_lt_${indx}`}><p className="font-[700]">{i.t}</p><span className="text-[#dedede]">{i.d}</span></div>
                            )
                        }
                    </div>
                </div>
            </div>

            <div className="w-full flex justify-center flex-wrap gap-3 py-10">
            {
                t.map((i, indx) => <div className="p-3 border-[#1d2b42] bg-[#101827]  border-[1px] rounded-xl flex gap-3 items-center font-monsterrat hover:scale-[1.01]">
                    <FaCheck color="#44ba38"/>{i}</div>)
            }
            </div>
            </div>
        </div>
    );
}

const Statistics = () => {
    const countUp = (element, endValue) => {
        let startValue = 0;
        const duration = 2000; // Duration of the animation in milliseconds
        const increment = endValue / (duration / 16); // Calculate the increment per frame (assuming 60fps)

        const updateCounter = () => {
            startValue += increment;
            if (startValue < endValue) {
                element.innerText = Math.ceil(startValue);
                requestAnimationFrame(updateCounter);
            } else {
                element.innerText = endValue;
            }
        };

        requestAnimationFrame(updateCounter);
    };

    const eventsRef = useIntersectionObserver(() => countUp(eventsRef.current, 60));
    const organizersRef = useIntersectionObserver(() => countUp(organizersRef.current, 13));
    const countriesRef = useIntersectionObserver(() => countUp(countriesRef.current, 3));
    return (
        <div
            className="w-full h-fit max-w-[100vw]"
        >
            <div className="flex flex-col items-center px-8 py-5 backdrop-blur-[2px] text-white bg-[#020917]">
                <p className="font-monsterrat text-[1rem] font-[700] text-center">Enhancing the quality and accessibility of motorsports events.</p>
                <span className="font-monsterrat text-[.8rem] font-[500] text-center text-[#e8e8e8] max-w-[600px]">Our services are designed to optimize and display results from your event in the best possible way. With just a few clicks, you can make live data accessible to all viewers and even transform it into live-generated TV graphics.</span>
                <div className="flex items-center justify-center gap-3">
                    <div className="flex flex-col items-center">
                        <p className="!text-[5rem] font-monsterrat font-[800] text-[#3cfffc]" ref={eventsRef}>0</p>
                        <p className="!text-[.9rem] !text-[#e8e8e8]">Events covered</p>
                    </div>
                    <div className="flex flex-col items-center">
                        <p className="!text-[5rem] font-monsterrat font-[800] text-[#3cfffc]" ref={organizersRef}>0</p>
                        <p className="!text-[.9rem] !text-[#e8e8e8]">Organizers</p>
                    </div>
                    <div className="flex flex-col items-center">
                        <p className="!text-[5rem] font-monsterrat font-[800] text-[#3cfffc]" ref={countriesRef}>0</p>
                        <p className="!text-[.9rem] !text-[#e8e8e8]">Countries</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

const Home = () => {

    const isTabletOrMobile = useMediaQuery({ query: "(max-width: 850px)" });

    return (
        <div className="rg-home">
            <div className="rg-home-inner">
                <Navbar isTabletOrMobile={isTabletOrMobile}/>
                <div className="mx-auto max-w-[1920px] w-full grid select-none">
                    <Title />
                    <Statistics />
                    <Interclude type="g" />
                    <Textarea />
                    <Panel />
                    {/* <Showcase /> */}
                    {/* <Interclude type="v" /> */}
                    <Services />
                    <Prices />
                    {/* <Interclude type="v"/>
                    <FAQ /> */}
                    {/* <Interclude type="v" reverse={true}/>
                    <About /> */}
                    <Footer bg={'#020817'}/>
                </div>

            </div>
        </div>
    );
};

export default Home;