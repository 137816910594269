
import { motion } from "framer-motion";
import { GiFullMotorcycleHelmet } from "react-icons/gi";

const colors = [
    "#f8a000", "#1e90ff", "#fa46a7", "#3fd95b", "#e6e5e5", "#e33e2b",
    "#a329c2", "#c2294c", "#cfc61d", "#cf701d", "#4d4d4d"
];

function classToColor(className, classArray) {
    const index = classArray.indexOf(className);
    return colors[index] || "green";
}


const generateLapsArray = (raceCompetitorsList, flag) => {
    const hasCompetitorWithMoreThanOneLap = raceCompetitorsList?.find(competitor => competitor.lap > 0);

    if (!hasCompetitorWithMoreThanOneLap) {
        return [];
    }

    const leaderLaps = raceCompetitorsList[0].lap;

    let initialLapsArray = [];
    for (let i = leaderLaps; i > 1 && initialLapsArray.length < 3; i--) {
        initialLapsArray.push(Math.floor(i));
    }

    let filteredLapsArray = initialLapsArray.filter(lap =>
        raceCompetitorsList.some(competitor => competitor.lap === lap.toString())
    );

    if (flag === "finish") {
        const maxLap = Math.max(...filteredLapsArray);
        filteredLapsArray = filteredLapsArray.filter(lap => lap <= maxLap);
    }

    return filteredLapsArray;
};

const groupCompetitors = (competitors, threshold) => {
    const groups = [];
    competitors.forEach(competitor => {
        if (competitor.status === "F") return;
        let added = false;
        for (let group of groups) {
            if (Math.abs(group[0].percentage - competitor.percentage) <= threshold) {
                group.push(competitor);
                added = true;
                break;
            }
        }
        if (!added) {
            groups.push([competitor]);
        }
    });
    return groups;
};

const Simulator = ({raceData, config}) => {

    const lapsArray = generateLapsArray(raceData || [], raceData?.raceDetails?.flag);
    // dont show anything
    // if (raceData?.raceDetails?.race_type_id !== 2) return null;
    // if (["None", "Warmup"].includes(raceData?.raceDetails?.flag)) return null;
    // if (lapsArray?.length === 0) return null;

    switch (config?.style) {
        case "k1": return (
            <div
                className="absolute"
                style={{
                    top: config?.buttons?.simulator?.top,
                    left: config?.buttons?.simulator?.left
                }}
            >
                <p>Approximate situation in track</p>
                <div>
                {
                    lapsArray.reverse().map((lap, index) => {
                        const competitorsInLap = raceData?.race_competitors_list?.filter((competitor) => competitor.lap === lap.toString());
                        const groupedCompetitors = groupCompetitors(competitorsInLap, 5); // Adjust threshold as needed

                        return (
                            <div key={`simulator_lap_${index}`} className="app_simulator_lap" style={{width: `${100 / lapsArray.length - 2}%`}}>
                                <div className="app_simulator_lap_number"><p><span>{`LAP ${lap + 1}`}</span></p></div>
                                <div
                                    className="app_simulator_lap_line"
                                >
                                    <>
                                    {raceData?.raceDetails?.flag === "Finish" ? <div className="app_simulator_finish_flag"/> : <span/>}
                                    {
                                        groupedCompetitors.map((group, groupIndex) => {
                                            const leftPosition = group[0].percentage;

                                            if (group.length === 1) {
                                                const competitor = group[0];
                                                return (
                                                    <div
                                                        className="app_simulator_competitor"
                                                        key={`sim_competitor_${group[0].nr}`}
                                                        style={{
                                                            left: `${leftPosition}%`,
                                                            background: classToColor(competitor.class, raceData?.race_details?.availableClasses)
                                                        }}
                                                    >
                                                        <div className="app_simulator_competitor_inner">
                                                            {competitor.nr}
                                                        </div>
                                                    </div>
                                                );
                                            }

                                            return (
                                                <div
                                                    className="app_simulator_competitor_group"
                                                    key={`sim_competitor_group_${group.map((competitor) => competitor.nr).join('_')}_${groupIndex}`}
                                                    style={{
                                                        position: "absolute",
                                                        left: `${leftPosition}%`,
                                                        zIndex: raceData?.race_competitors_list?.length * 2 - index + 1,
                                                        background: 'white',
                                                        color: '#111a28'
                                                    }}
                                                >
                                                    < GiFullMotorcycleHelmet size={20}/>
                                                    <p>{group.length > 1 ? `${group.length}` : group[0].nr}</p>
                                                    {
                                                        // hoveredGroup?.lapIndex === index && hoveredGroup?.groupIndex === groupIndex &&
                                                        // <div className="app_simulator_tooltip">
                                                        //     {group.reverse().map((competitor) => (
                                                        //         <div
                                                        //             className="app_simulator_tooltip_competitor"
                                                        //             key={`tooltip_competitor_${competitor.nr}`}
                                                        //             style={{background: classToColor(competitor.class, raceData?.race_details?.availableClasses)}}
                                                        //         >
                                                        //             {competitor.nr}
                                                        //         </div>
                                                        //     ))}
                                                        // </div>
                                                    }
                                                </div>
                                            );
                                        })
                                    }
                                    </>
                                </div>
                            </div>
                        )
                    })
                }
                </div>
            </div>
        );
    }


}

export default Simulator;