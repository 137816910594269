
import Switch from "../../../../../components/custom/Switch";
import { Confirmation } from "../ConfirmAction";
import { flagToColor } from "../../../../../Functions";
import useGetActiveRaces from "../../../../../hooks/useGetActiveRaces";
import useSetRaceKey from "../../../../../hooks/useSetRaceKey";
import { useState } from "react";

import { FaFlag } from "react-icons/fa6";

const DummyGenerator = () => {
    const set = useSetRaceKey();
    const [cnt, setCnt] = useState(10);
    const [sidecars, setSidecars] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const dummyMessage = "This will generate dummy test data that will be static and can be used to test out your graphics setup with realistic data. Do you want to continue?";

    const handleCntChange = (e) => {
        const value = Math.max(5, Math.min(35, Number(e.target.value)));
        setCnt(value);
    };

    return (
        <div className="flex gap-2 mb-2 justify-center relative">
            <div className="flex gap-2 items-center">
                <p>Competitors</p>
                <input
                    type="number"
                    value={cnt}
                    onChange={handleCntChange}
                    min="5"
                    max="35"
                    className="border p-1 rounded"
                />
            </div>
            <hr className="w-[1px] h-[30px] border-none bg-[#e2e8f0] mx-2"/>
            <div className="flex gap-2 items-center">
                <p className="mr-4">Sidecar mode</p>
                <Switch rvalue="On" lvalue="Off" onChange={() => setSidecars(prev => !prev)}/>
            </div>
            <hr className="w-[1px] h-[30px] border-none bg-[#e2e8f0] mx-2"/>
            <button
                className="py-1 px-4 bg-[#151a27] hover:bg-[#5d60ef] text-white rounded-md"
                onClick={() => {setShowConfirmation(true)}}
            >Generate</button>
            {showConfirmation && (
                <Confirmation
                    message={dummyMessage}
                    callback={(conf) => {
                        if (conf) {
                            set('dummy', true, {count: cnt, sidecars: sidecars});
                        }
                        setShowConfirmation(false)
                    }}
                />
            )}
        </div>
    );
}

const AvailableRacesComponent = () => {

    const { active, loading } = useGetActiveRaces();
    const set = useSetRaceKey();
    // TODO: make this so event without token you can access races
    // TODO: add dummy race that can be generated

    const ret = {
        loading:
            <div className="w-full h-full flex flex-col gap-2 items-center justify-center text-[#222222]">
                <div className="border-[32px] rounded-[50%] w-[120px] h-[120px] spinner"/>
                <h4>Loading active race list...</h4>
            </div>,
        noData:
            <div className="w-full h-full flex flex-col gap-2 items-center justify-center text-white bg-[#151a27]">
                <div className="w-fit h-fit p-3 py-12 bg-[#151a27] text-center rounded-md">
                    <p className="text-[1.1rem] font-bold">{active.error ? "Error" : "Looks like nothing is happening right now!"}</p>
                    <span>{
                        active.error || "Try refreshing races from the Races tab to see if any race is available"
                    }</span>
                </div>
            </div>,
        ok:
            <div className="w-full h-full relative p-3 text-[#222222]">
                <header className="w-full h-[100px] text-center">
                    <span className="text-[1.9rem] font-[700]">Dummy race</span>
                    <p className="text-[.9rem]">Generate dummy data to test your setup.</p>
                </header>
                <DummyGenerator />
                <hr className="mb-5"/>
                <header className="w-full h-[100px] text-center">
                    <span className="text-[1.9rem] font-bold">Available races</span>
                    <p className="text-[.9rem]">Browse between currently active races and pick your one.</p>
                </header>
                <table className="w-full">
                    <thead className="table-head border-b">
                        <tr>
                            <th>Race Type</th>
                            <th>Championship</th>
                            <th>Track</th>
                            <th>Flag</th>
                            <th className="!text-center">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            active && active?.raceData?.map((race, index) =>
                                <tr
                                    className="h-8 border-b"
                                    key={`race-hor-item${index}`}
                                >
                                    <td>{race?.race_type}</td>
                                    <td>{race?.championship}</td>
                                    <td>{race?.track_name}</td>
                                    <td>
                                        {
                                            race?.flag === "None" ?
                                            <span className="px-2 py-1 rounded-md bg-[#ef4444] text-white">No flag</span>
                                            :
                                            <FaFlag color={flagToColor(race?.flag)} size={"15px"} title={race?.flag}/>
                                        }
                                    </td>
                                    <td>
                                        <button
                                            className="bg-[#5d60ef] text-white w-full h-[30px] rounded-md"
                                            onClick={() => set(race?.token)}
                                        >Select</button></td>
                                </tr>
                            )
                        }
                    </tbody>
                </table>
            </div>
    }

    return loading ? ret.loading : ret.ok;
};

export default AvailableRacesComponent;