
import { useEffect, useState } from "react";
import MessageStore from "../../../../../../../managers/ConsoleMessageManager"
import { useSelector } from "react-redux";
import { Button } from "../../../../../../../components/ui/button";

// Icons
import { MdOutlineSearchOff, MdEdit, MdDelete } from "react-icons/md";
import { IoIosColorPalette } from "react-icons/io";

import { ConfirmationNewPalette } from "../../../ConfirmAction";

const isColorLight = (color) => {
    // Convert hex color to RGB
    const hex = color.replace('#', '');
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    // Calculate luminance
    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

    // Return true if color is light, false if dark
    return luminance > 0.5;
};

const InteractiveMyDesigns = ({socket}) => {

    const config = useSelector(state => state.config);

    const [ designs, setDesigns ] = useState([]);

    const [ createPalette, setCreatePalette ] = useState(false);
    const [ editPalette, setEditPalette ] = useState(false);
    const [ editablePalette, setEditablePalette ] = useState(null);

    const getDesigns = async () => {
        await socket.emit('design', { action: "GET_ALL" }, (response) => {
            setDesigns(response?.data);
        });
    };

    const deleteDesign = async (id) => {
        await socket.emit('design', { action: "DELETE", data: id }, async (response) => {
            await getDesigns();
        });
    }

    const setDesign = async (design) => {
        await socket.emit('update', { what: 5, data: ['palette', design]}, (response) => {

        });
    }


    useEffect(() => {
        getDesigns();
    }, []);

    const colorItemMap = [
        {name: "Primary", key: "primary"},
        {name: "Secondary", key: "secondary"},
        {name: "Accent", key: "accent"},
        {name: "Row Even", key: "even"},
        {name: "Row Odd", key: "odd"},
    ];

    return (
        <div className="w-full h-full flex font-geist text-[#151a27]">
            {
                createPalette && <ConfirmationNewPalette socket={socket} callback={() => setCreatePalette(false)} reload={getDesigns} colors={{}}/>
            }
            {
                editPalette && <ConfirmationNewPalette socket={socket} callback={(colorObject) => { setEditPalette(false); colorObject === typeof Object && setDesign(colorObject)}} newPalette={false} colors={editablePalette} reload={getDesigns}/>
            }
            <div className="w-full h-full flex flex-col items-center p-[15px] relative pb-[60px]">
                <div className="w-full flex flex-col gap-[5px] overflow-x-auto overflow-scrollbar-h">
                {
                    designs?.length ?
                    <>
                    {
                        [...designs].sort((a, b) => b._id === config?.palette?._id ? 1 : -1).map((design, index) =>
                            <div
                                className="w-full h-fit p-[10px] border-[1px] border-[#e4e4e7] rounded-md shadow-md flex-shrink-0 mb-2 bg-[#0c111c] text-white"
                                key={`design_item_${index}`}
                            >
                                <h1 className="text-[1.2rem] font-[500] mb-2">{design?.name}</h1>
                                <div className="flex">
                                {
                                    colorItemMap.map((colorItem, index2) => {
                                        const backgroundColor = colorItem?.key ? design?.palette?.[colorItem?.key] : "";
                                        const textColor = isColorLight(backgroundColor) ? "#020817" : "#FFFFFF";

                                        return (
                                            <div
                                                className="w-full h-[100px] flex flex-col justify-center px-2"
                                                key={`color_item_${index2}_${index}`}
                                                style={{ background: backgroundColor }}
                                            >
                                                <p className="text-white" style={{ color: textColor }}>{`${colorItem?.name}`}</p>
                                                <p className="text-white text-[.6rem]" style={{ color: textColor }}>{backgroundColor}</p>
                                            </div>
                                        );
                                    })
                                }
                                </div>
                                <div className="flex gap-2 mt-2">
                                    <Button
                                        className={`w-fit hover:bg-[#5d60ef] ${
                                            config?.palette?._id === design?._id ? "bg-[#5d60ef]" : "bg-[#242d45]"
                                        }`}
                                        onClick={() => setDesign(design)}
                                    >{config?.palette?._id === design?._id ? "Selected palette" : "Use this palette"}</Button>
                                    <Button
                                        className='bg-[#242d45] hover:bg-[#5d60ef]'
                                        onClick={() => {
                                            setEditPalette(true);
                                            setEditablePalette(design);
                                        }}
                                    ><MdEdit size={"25px"}/></Button>
                                    <Button
                                        onClick={() => deleteDesign(design?._id)}
                                        className='bg-[#242d45] hover:bg-[#5d60ef]'
                                        disabled={config?.palette?._id === design?._id ? true : false}
                                    ><MdDelete size={"25px"}/></Button>
                                </div>
                            </div>
                        )
                    }
                    </>
                    :
                    <div className="no-designs-placeholder">
                        <MdOutlineSearchOff size={"30%"}/>
                        <p>No designs found to display</p>
                        <Button className='bg-[#151a27] hover:bg-[#5d60ef] mt-2 px-9'>Reload</Button>
                    </div>
                }
                </div>
                <div className="absolute bottom-[10px] left-[10px] flex items-center gap-[5px]">
                    <Button
                        className='gap-[5px] bg-[#151a27] hover:bg-[#5d60ef]'
                        onClick={() => setCreatePalette(true)}
                    >
                        <IoIosColorPalette size={"18px"}/>
                        <p>Create new color palette</p>
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default InteractiveMyDesigns;