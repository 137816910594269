import "./Admin.css";

import { Route, Routes } from "react-router-dom";
import React, { useEffect, useState } from "react";
import io from "socket.io-client";
import { useDispatch } from "react-redux";
import useAuth from "../../hooks/useAuth";

// Components for admin panel
import ControlPanelComponent from "./AdminPanelComponents/ControlPanel/ControlPanel";
import PageNotFound from "./AdminPanelComponents/NotFound/NotFound";
import { getRaceBackendAddress } from "../../Functions";

import Timestamps from "./AdminPanelComponents/ControlPanel/HorizontalBar/GraphTimestamps";

const socketLogger = (socket, dispatch) => {
    let logBuffer = [];

    const logEvent = (event, data) => {
        const log = { event, data, timestamp: new Date().toLocaleTimeString() };
        logBuffer.push(log);
    };

    socket.onAny((event, ...args) => {
        logEvent(event, args);
    });

    const interval = setInterval(() => {
        if (logBuffer.length > 0) {
            console.log("adding logs ", logBuffer.length);
            dispatch({ type: "ADD_SOCKET_LOG", payload: logBuffer });
            logBuffer = [];
        }
    }, 1000);

    return () => {
        clearInterval(interval);
        socket.offAny(logEvent);
    };
};

const Admin = React.memo(() => {

    const { auth } = useAuth();
    const dispatch = useDispatch();
    const [socket, setSocket] = useState(null);
    const [loading, setLoading] = useState(true);
    const [config, setConfig] = useState({});

    useEffect(() => {
        const connectToSocketServer = async () => {
            console.log(auth)
            const sock = io.connect(getRaceBackendAddress(), {
                query: {
                    userType: "Admin Panel",
                    userId: auth.userId,
                    userName: auth.user
                },
            });

            // Listen for connection errors
            sock.on('connect_error', (err) => {
                console.error('Connection error:', err);
            });

            // Listen for general errors
            sock.on('error', (err) => {
                console.error('Socket error:', err);
            });

            console.log(sock, getRaceBackendAddress());
            const cleanup = socketLogger(sock, dispatch);
            setSocket(sock);
            setLoading(false);

            return cleanup;
        };

        connectToSocketServer().then(cleanup => {
            return () => {
                if (cleanup) cleanup();
                if (socket) {
                    console.log("disconnecting socket");
                    socket.disconnect();
                }
            };
        });
    }, [auth.userId, auth.userName, dispatch]);

    useEffect(() => {
        if (socket) {
            socket.on("getApi", (data) => {
                dispatch({ type: "SET_DATA", payload: data });
                Timestamps.addTimestamp(Date.now());
            });
            socket.on("getConfig", (data) => {
                dispatch({ type: "SET_CONFIG", payload: data });
                setConfig(data);
            });
            socket.on("sendErrors", (data) => {
                dispatch({ type: "SET_ERROR", payload: data });
            });
        }
    }, [socket]);

    const ret = {
        loading: <div>Loading...</div>,
        ok: <div className="app__admin">
                <Routes>
                    <Route path={"/controlPanel"} element={ <ControlPanelComponent socket={socket} config={config} /> } />
                    <Route path="*" element={<PageNotFound socket={socket}/>} />
                </Routes>
            </div>
    }

    return loading ? ret.loading : ret.ok;
});
export default Admin;