
import "./Buttons.scss";
import CustomButtonsConfig from "./CustomButtonsConfig";
import { Red } from "../../../../../Constants";

import { MdOutlineStar } from "react-icons/md";
import { BsFillGrid3X3GapFill } from "react-icons/bs";
import { MdDisabledByDefault } from "react-icons/md";
import { FaKey } from "react-icons/fa6";
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";

// for console loggin
import MessageStore from "../../../../../managers/ConsoleMessageManager";

import { designData } from "../../../../../config/fields";

import { VscError } from "react-icons/vsc";
import { IoMdWarning, IoIosConstruct } from "react-icons/io";

import { useButtonValidator, validationErrorLevels } from "./stateValidator";

import styled, { keyframes, css } from 'styled-components';
import { Confirmation } from "../ConfirmAction";
import { pageNames } from "../../../../../config/namespaces";


const warnFlashAnimation = keyframes`
    0% { background-color: #e4e4e7; }
    50% { background-color: Orange; }
    100% { background-color: #e4e4e7; }
`;
const errorFlashAnimation = keyframes`
    0% { background-color: #e4e4e7; }
    50% { background-color: ${Red}; }
    100% { background-color: #e4e4e7; }
`;


const showToBackground = (show, level) => {
    if (show) return "#5d60ef";
    //if (level === validationErrorLevels.CONSTRUCTION) return "linear-gradient(45deg, #f7980a 25%, #020817 25%, #020817 50%, #f7980a 50%, #f7980a 75%, #020817 75%, #020817)"
    return "#e4e4e7";
}

const errorLevelToAnimation = (level) => {
    switch (level) {
        case validationErrorLevels.ERROR: return css`${errorFlashAnimation} 1s infinite`;
        case validationErrorLevels.WARN: return css`${warnFlashAnimation} 3s infinite`;
        default: return 'none';
    }
}

const InteractiveButtonStyled = styled.div`
    background: ${props => showToBackground(props.show, props.errorLevel)};
    animation: ${props => errorLevelToAnimation(props.errorLevel)};
    background-size: 28.28px 28.28px;
    `;

const InteractiveButtonKeyAssigner = ({buttonConfig, buttonName, socket, cb}) => {
    const [waiting, setWaiting] = useState(false);
    const [flash, setFlash] = useState(false);
    const [currentKey, setCurrentKey] = useState("-");

    const config = useSelector(state => state.config);

    useEffect(() => {
        setCurrentKey(buttonConfig?.key || "-");
    }, [buttonConfig]);

    const isDuplicateKey = (newKey) => {
        if (!config || !config.buttons) {
            return false;
        }
        const keys = Object.entries(config?.buttons)
        .filter(([key, button]) => key !== buttonName)
        .map(([key, button]) => button?.key);
        return keys.includes(newKey);
    };

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (isDuplicateKey(event?.key)) {
                MessageStore.addMessage({
                    status: 0,
                    message: `[${buttonName}] : Key already assigned to another button`
                });
                setFlash(true);
                setTimeout(() => { setFlash(false) }, 2000);
            }
            setCurrentKey(event.key);
            setWaiting(false);
        };

        if (waiting) {
            window.addEventListener('keydown', handleKeyDown);
        }

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [waiting]);

    const setButtonKey = () => {
        socket.emit("update", {what: 6, data: [buttonName, "key", currentKey]}, (callback) => {
            MessageStore.addMessage(callback);
        });
    };

    return (
        <div className="w-full h-full absolute top-0 left-0 !bg-[#020817]">
            <header className="!w-full !text-center !m-0 !rounded-none !block ">Click to assign</header>
            <div
                className={
                    `w-full h-[calc(100%-20px)] flex justify-center relative
                    ${waiting ? "waiting" : ""}
                    ${flash ? "flash" : ""}
                `}
                onClick={(event) => {event.stopPropagation(); setWaiting(true);}}
            ><p className="mt-5 text-[1.2rem]">{currentKey}</p></div>
            <button
                className="absolute w-[calc(100%-20px)] ml-[10px] bottom-[10px] bg-[#5d60ef] rounded-md shadow-sm"
                onClick={(event) => { event.stopPropagation(); setButtonKey(); cb();}}
            >Save</button>
        </div>
    );
}

const getErrorColor = (error) => {
    switch (error) {
        case validationErrorLevels.ERROR: return Red;
        case validationErrorLevels.CONSTRUCTION: return "yellow";
        default: return "orange";
    }
}


const InteractiveButtonSimple = ({buttonData, socket, config, errors}) => {

    const buttonName = buttonData[0];
    const buttonConfig = buttonData[1];

    return (
        <div className="bg-[#222222] w-fit h-fit p-1 mx-[2px] text-[1.2rem]">
            <InteractiveButtonStyled
                className="interactive-button-simple px-2"
                id={`interactive_button_${buttonName}`}
                show={buttonConfig.show}
                errorLevel={errors[buttonName]?.level}
            >
                {buttonConfig.name}
            </InteractiveButtonStyled>
        </div>
    );
}


const InteractiveButton = ({buttonData, socket, config, errors}) => {

    const [showConfirmation, setShowConfirmation] = useState(false);
    const [pendingAction, setPendingAction] = useState(null);
    const [openWidget, setOpenWidget] = useState(false);

    // used to assign a keybind for button
    const [buttonKey, setButtonKey] = useState(false);

    const buttonName = buttonData[0];
    const buttonConfig = buttonData[1];
    const buttonCustomData = CustomButtonsConfig[buttonName];

    const errorsMap = {
        "ERROR": <VscError size={"20px"}/>,
        "WARN": <IoMdWarning size={"20px"}/>,
        "CONSTRUCTION": <IoIosConstruct size={"20px"}/>
    };

    const dispatch = useDispatch();

    const buttonHeaderElements = [
        {
            onClick: () => { handleOnClickEvent("favorite") },
            element: <MdOutlineStar color={buttonConfig.favorite ? "yellow" : "white"} size={"80%"}/>
        },
        {
            onClick: () => {
                dispatch({type: "SET_PROPS", payload: buttonData});
                dispatch({type: "SET_PAGE", payload: pageNames.GraphicsPosition});
            },
            element: <BsFillGrid3X3GapFill size={"80%"}/>
        },
        {
            onClick: () => {
                setButtonKey(true);
            },
            element: <FaKey size={"65%"}/>
        },
        {
            onClick: () => { handleOnClickEvent("enabled") },
            element: <MdDisabledByDefault size={"80%"} color={Red}/>
        }
    ];

    const handleOnClickEvent = (name) => {
        if (name === "enabled" && buttonConfig.show) {
            MessageStore.addMessage({
                status: 0, message: `[${buttonName}] : Cannot disable a button that is currently being shown`
            });
            return;
        }

        if (name === "enabled") {
            setPendingAction({ name, value: !buttonConfig[name] });
            setShowConfirmation(true);
        } else {
            // TODO: Allows errors to be overridden by user, needs fixing
            // if (errors[buttonName]?.message && !config?.allowErrorOverride) {
            //     return;
            // }

            socket.emit("update", {
                what: 6,    // button
                data: [
                    buttonName,
                    name,
                    !buttonConfig[name]
                ]
            }, (callback) => {
                MessageStore.addMessage(callback);
            });
        }
    };

    const handleConfirmation = (confirmed) => {
        if (confirmed && pendingAction) {

            socket.emit("update", {
                what: 6,    // button
                data: [
                    buttonName,
                    pendingAction.name,
                    pendingAction.value
                ]
            }, (callback) => {
                MessageStore.addMessage(callback);
            });
        }

        setPendingAction(null);
        setShowConfirmation(false);
    };

    const showButtonState = () => {
        if (errors[buttonName]) {
            return (
                <div
                    className="button-error-container"
                    style={{justifyContent: errors[buttonName]?.message.length > 22 ? "flex-start" : "center"}}
                >
                    <p
                        className={errors[buttonName]?.message.length > 22 ? "scroll" : ""}
                        key={`error_${buttonName}`}
                    >{errors[buttonName]?.message}</p>
                </div>

            );
        }
        return (
            <span
                style={{background: buttonConfig.show ? "#5d60ef" : "#e4e4e7"}}
            >{buttonConfig.show ? "Activated" : ""}</span>
        );
    }

    const setNewComponent = (key, props = {}) => {
        Object.keys(CustomButtonsConfig).forEach((parentKey) => {
            const id = CustomButtonsConfig[parentKey].find(item => item.key === key)?.id;
            id && dispatch({type: "SET_PAGE", payload: id});
        });
    }

    return (
        <div className="interactive-button-wrapper">
            <InteractiveButtonStyled
                className="interactive-button"
                id={`interactive_button_${buttonName}`}
                show={buttonConfig.show}
                errorLevel={errors[buttonName]?.level}
            >
                {showConfirmation && (
                    <Confirmation
                        message={`Are you sure you want to hide ${buttonConfig.name} graphic?`}
                        callback={handleConfirmation}
                    />
                )}
                <header>
                    {
                        buttonHeaderElements.map((entry, index) =>
                            <div
                                className="interactive-button-header-item"
                                key={`header_item_${index}_${buttonName}`}
                                onClick={() => entry.onClick()}
                            >{entry.element}</div>
                        )
                    }
                </header>
                <div className="interactive-button-screen">
                    <div className="interactive-button-screen-side">
                        <div
                            className="interactive-button-screen-side-inner"
                            style={{
                                marginRight: "0", marginLeft: "3px",
                                borderTopRightRadius: "0px",
                                borderTopLeftRadius: "4px"
                            }}
                        />
                    </div>
                    <div
                        className="interactive-button-screen-center"
                        onClick={() => handleOnClickEvent("show")}
                    >
                        {
                            errors[buttonName] &&
                            <div
                                className="interactive-button-error"
                                title={errors[buttonName]?.message}
                                style={{
                                    color: getErrorColor(errors[buttonName]?.level)
                                }}
                            >{errorsMap[errors[buttonName]?.level]}</div>
                        }
                        {
                            buttonKey &&
                            <InteractiveButtonKeyAssigner
                                buttonConfig={buttonConfig}
                                buttonName={buttonName}
                                socket={socket}
                                cb={() => setButtonKey(false)}
                            />
                        }
                        <div className="interactive-button-screen-center-keybind">
                            {buttonConfig.key || "-"}
                        </div>
                        <div className="interactive-button-screen-center-name">
                            {buttonConfig.name}
                        </div>
                    </div>
                    <div
                        className="interactive-button-screen-side"
                    >
                        <div className="interactive-button-screen-side-inner">
                        {
                            buttonCustomData && !buttonKey &&
                            buttonCustomData.map((buttonObject, index) =>
                                !buttonObject.elementEmbedded &&
                                <div
                                    className="interactive-button-side-item"
                                    key={`header_item_favorite_${index}`}
                                    onClick={() => setNewComponent(buttonObject.key, {socket: socket, config: config})}
                                >
                                    {buttonObject.icon}
                                </div>
                            )
                        }
                        {
                            buttonCustomData && buttonCustomData.filter(i => i.elementEmbedded).length > 0 &&
                            <div
                                className="interactive-button-toggle-embedded"
                                onClick={() => setOpenWidget(prev => !prev)}
                            >{openWidget ? '<' : '>'}</div>
                        }
                        </div>
                    </div>
                </div>
                <div
                    className="interactive-button-bottom"
                >
                    {
                        showButtonState()
                    }
                </div>
            </InteractiveButtonStyled>
            {
                openWidget && buttonCustomData &&
                buttonCustomData.filter(i => i.elementEmbedded).map((item, index) =>
                    <div className="interactive-button-embedded" key={`embedded_${index}`}>
                        <item.el socket={socket} />
                    </div>
                )
            }
        </div>
    );
};

const SkeletonButton = () => {
    return (
        <div className="interactive-button btn-skeleton" />
    );
}

export const FavoriteButtons = ({ config, socket, errors }) => {

    const { buttons } = config;
    const anyFavorites = buttons ? Object.values(buttons)?.some((button) => button?.favorite && button?.enabled) : {};

    return (
        <div className="interactive-buttons-favorite" id="interactive_b_f">
            <div className="interactive-buttons-favorite-name">{
                anyFavorites &&  <><MdOutlineStar size={"15px"}/>Favorite Graphics</>
            }
            </div>
            {
                buttons ?
                anyFavorites &&
                Object.entries(buttons).filter((button) =>
                    button[1].favorite && button[1].enabled &&
                    designData[config.style].graphics.includes(button[0])
                ).map((button, index) =>
                    <InteractiveButton
                        buttonData={button}
                        socket={socket}
                        config={config}
                        errors={errors}
                        key={`interactive-bttn-fav-${index}`}
                    />
                )
                : Array(5).fill().map((_, index) =>
                    <SkeletonButton key={`skeleton-bttn-${index}`} />
                )
            }
        </div>
    );
};

const AvailableButtons = ({ config, socket, errors }) => {
    const { buttons } = config;
    const el = document.getElementById("interactive_b_f");

    const [selected, setSelected] = useState(1);
    const [showMode, setShowMode] = useState(false);
    const [mode, setMode] = useState(1); // 1 basic - 2 advanced

    const getButtonStyle = (isSelected) => ({
        background: isSelected ? "#5d60ef" : "#e4e4e7",
        color: isSelected ? "white" : "#222222"
    });

    return (
        <div className="interactive-buttons-available">
            <div
                className="interactive-buttons-available-name"
                style={{left: el?.style.left}}
            >
                Enabled graphics
                {/* <button style={getButtonStyle(selected === 1)}>Enabled Graphics</button>
                <button style={getButtonStyle(selected === 2)}>Race Simulation</button> */}
            </div>
            <button className="interactive-buttons-available-mode-expand" onClick={() => setShowMode(prev => !prev)}>{ showMode ? '<' : '>'}</button>
            {
                showMode &&
                <div className="interactive-buttons-available-modes">
                    <p>Layout</p>
                    <button title="Advanced" onClick={() => setMode(1)}>Advanced</button>
                    <button title="Basic" onClick={() => setMode(2)}>Basic</button>
                </div>

            }
            {
                mode === 1 &&
                <div className="interactive-buttons-available-buttons">
                {
                    buttons ?
                    Object.entries(buttons).filter((button) =>
                        !button[1].favorite && button[1].enabled &&
                        designData[config.style].graphics.includes(button[0])
                    ).map((button, index) =>
                        <InteractiveButton
                            buttonData={button}
                            socket={socket}
                            config={config}
                            errors={errors}
                            key={`interactive-bttn-enabled-${index}`}
                        />
                    )
                    :
                    Array(6).fill().map((_, index) =>
                        <SkeletonButton key={`skeleton-bttn-${index}`} />
                    )
                }
                </div>
            }
            {
                mode === 2 &&
                <div className="flex gap-2 mt-5 flex-wrap">
                {
                    buttons ?
                    Object.entries(buttons).filter((button) =>
                        !button[1].favorite && button[1].enabled &&
                        designData[config.style].graphics.includes(button[0])
                    ).map((button, index) =>
                        <InteractiveButtonSimple
                            buttonData={button}
                            socket={socket}
                            config={config}
                            errors={errors}
                            key={`interactive-bttn-enabled2-${index}`}
                        />
                    )
                    :
                    Array(6).fill().map((_, index) =>
                        <SkeletonButton key={`skeleton-bttn-${index}`} />
                    )
                }
                </div>
            }
        </div>
    );
};


const InteractiveButtons = ({ config, socket }) => {

    const errors = useButtonValidator();

    return (
        <div className="interactive-buttons-wrapper">
            <div className="interactive-buttons-component">
                <div className="interactive-buttons-content">
                    <AvailableButtons config={config} socket={socket} errors={errors}/>
                </div>
            </div>
        </div>
    );
}

export default InteractiveButtons;