

// What graphics are available for what design configuration.

class Graphics {
    static verticalScoreboard = "verticalScoreboard";
    static horizontalScoreboard = "horizontalScoreboard";
    static resultsByClass = "resultsByClass";
    static startGrid = "startGrid";
    static raceResults = "raceResults";
    static battles = "battles";
    static weather = "weather";
    static timer = "timer";
    static announcer = "announcer";
    static fastestLap = "fastestLap";
    static raceLeader = "raceLeader";
    static winner = "winner";
    static replay = "replay";
    static trackPlan = "trackPlan";
    static interviewCompetitor = "interviewCompetitor";
    static interviewCustom = "interviewCustom";
    static scheduleNow = "scheduleNow";
    static scheduleAll = "scheduleAll";
    static pointStandings = "pointStandings";
    static safetyCar = "safetyCar";
    static Formation = "Formation";
    static simulator = "simulator";
}


// Used in select graphics tab to add description and other data.

export const selectorGraphicsDesc = {
    [Graphics.verticalScoreboard]: {
        description: "Displays race results in vertical column format."
    },
    [Graphics.horizontalScoreboard]: {
        description: "Displays race results in horizontal row format."
    },
    [Graphics.resultsByClass]: {
        description: "Displays results based on selected class."
    },
    [Graphics.startGrid]: {
        description: "Displays how competitors are aligned at the start of the race."
    },
    [Graphics.raceResults]: {
        description: "Displays all competitors and their post race results in a table format."
    },
    [Graphics.battles]: {
        description: "Shows gap between selected competitors and position they are fighting for."
    },
    [Graphics.weather]: {
        description: "Show weather conditions on track."
    },
    [Graphics.timer]: {
        description: "Minimalistic graphic to show remaining/elapsed time/laps and race name."
    },
    [Graphics.announcer]: {
        description: "Lets you type a message to display for your audience on screen."
    },
    [Graphics.fastestLap]: {
        description: "Shows which competitor has the fastest lap of the run."
    },
    [Graphics.raceLeader]: {
        description: "Shows current leader of the race based on laps/time"
    },
    [Graphics.winner]: {
        description: "Shows the winner of the race."
    },
    [Graphics.replay]: {
        description: "A small box with 'Replay' written on it do display when you are using a replay."
    }
};

// Used for buttons to determine which graphics are available
// for a set design.

export const designData = {
    'v2': {
        name: "Original",
        areColorsAvailable: true,
        graphics: [
            Graphics.verticalScoreboard,
            Graphics.resultsByClass,
            Graphics.startGrid,
            Graphics.raceResults,
            Graphics.battles,
            Graphics.weather,
            Graphics.timer,
            Graphics.fastestLap,
            Graphics.raceLeader,
            Graphics.winner,
            Graphics.replay,
            Graphics.scheduleAll,
            Graphics.scheduleNow,
            Graphics.interviewCompetitor,
            Graphics.interviewCustom,
            Graphics.pointStandings,
            Graphics.Formation,
            Graphics.safetyCar
        ],
        sizes: {
            [Graphics.verticalScoreboard]: {
                w: 400,
                h: 960
            },
            [Graphics.resultsByClass]: {
                w: 320,
                h: 760
            },
            [Graphics.startGrid]: {
                w: 1680,
                h: 1000
            },
            [Graphics.raceResults]: {
                w: 1000,
                h: 1000
            },
            [Graphics.battles]: {
                w: 840,
                h: 360
            },
            [Graphics.weather]: {
                w: 640,
                h: 240
            },
            [Graphics.timer]: {
                w: 640,
                h: 45
            },
            [Graphics.fastestLap]: {
                w: 360,
                h: 240
            },
            [Graphics.raceLeader]: {
                w: 360,
                h: 240
            },
            [Graphics.winner]: {
                w: 280,
                h: 220
            },
            [Graphics.replay]: {
                w: 200,
                h: 60
            }
        }
    },
    'k1': {
        name: "Karting",
        areColorsAvailable: false,
        graphics: [
            Graphics.verticalScoreboard,
            Graphics.resultsByClass,
            Graphics.startGrid,
            Graphics.raceResults,
            Graphics.battles,
            Graphics.weather,
            Graphics.timer,
            Graphics.fastestLap,
            Graphics.raceLeader,
            Graphics.winner,
            Graphics.replay,
            Graphics.announcer,
            Graphics.scheduleAll,
            Graphics.scheduleNow,
            Graphics.interviewCompetitor,
            Graphics.interviewCustom,
            Graphics.pointStandings,
            Graphics.Formation,
            Graphics.safetyCar,
            Graphics.simulator
        ],
        sizes: {
            [Graphics.verticalScoreboard]: {
                w: 400,
                h: 960
            },
        }
    },
    'm1': {
        name: "Motocross",
        areColorsAvailable: false,
        graphics: [
            Graphics.verticalScoreboard,
            Graphics.horizontalScoreboard,
            Graphics.resultsByClass,
            Graphics.startGrid,
            Graphics.raceResults,
            Graphics.battles,
            Graphics.timer,
            Graphics.fastestLap,
            Graphics.raceLeader,
            Graphics.scheduleAll,
            Graphics.scheduleNow,
            Graphics.interviewCompetitor,
            Graphics.interviewCustom,
            Graphics.pointStandings,
            Graphics.Formation,
            Graphics.safetyCar
        ],
        sizes: {
            [Graphics.verticalScoreboard]: {
                w: 362,
                h: 923
            },
            [Graphics.horizontalScoreboard]: {
                w: 1920,
                h: 340
            },
            [Graphics.resultsByClass]: {
                w: 310,
                h: 480
            },
            [Graphics.startGrid]: {
                w: 768,
                h: 1033
            },
            [Graphics.raceResults]: {
                w: 900,
                h: 1033
            },
            [Graphics.timer]: {
                w: 640,
                h: 50
            }
        }
    },
    'mod': {
        name: "Modern",
        areColorsAvailable: true,
        graphics: [
            Graphics.timer,
        ],
        sizes: {
            [Graphics.timer]: {
                w: 640,
                h: 50
            }
        }
    },
    'sm': {
        name: "Smart",
        areColorsAvailable: false,
        graphics: [],
        sizes: {}
    }
};
