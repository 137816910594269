
import useTokenTime from "../../../../../../hooks/useTokenTime";
import { IoTime } from "react-icons/io5";

const TokenStatus = ({ socket }) => {

    const { tokenTime, loading } = useTokenTime(socket);

    const ret = {
        loading: (
            <div className="horizontalBar-component-user-data-waiting">
                <p>Loading token info<span className="dots"><span>.</span><span>.</span><span>.</span></span></p>
            </div>
        ),
        ok: (
            <div className="horizontalBar-component-user-data-info">
                <div className="horizontalBar-component-user-data-info-time-left">
                    <span
                        style={{
                            background: `linear-gradient(to right, #5d60ef ${tokenTime?.percentage}%, #1c2336 ${tokenTime?.percentage}%)`,
                            transition: 'background 0.3s ease-in-out'
                        }}
                    ><IoTime size={"20px"}/><p>{tokenTime?.time}</p></span>
                </div>
            </div>
        )
    }
    return loading ? ret.loading : ret.ok;
}

export default TokenStatus;