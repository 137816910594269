
const initialState = {
    logs: [],
};

const socketLogReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'ADD_SOCKET_LOG':
            const newLogs = [...state.logs, ...action.payload];
            return {
                ...state,
                logs: newLogs.length > 10 ? newLogs.slice(newLogs.length - 10) : newLogs,
            };
        default:
            return state;
    }
};


export default socketLogReducer;