import { useEffect, useState } from "react";
import useAuth from "../../../../../../../hooks/useAuth";

const InteractiveEnabledGrid = () => {

    const { auth } = useAuth();

    // http://localhost:3000
    // Roberts
    // Testuser123!
    // .slice(0, -4)
    const link = `http://localhost:3000/graphics/${encodeURIComponent(auth.userId)}`

    const handleResize = () => {
        const iframe = document.getElementById('interactive_main_grid_src');
        const wrapper = document.getElementById('interactive-screen-main-enabled-grid-wrapper');

        const scale = Math.min(
            wrapper.offsetWidth / 1920,
            wrapper.offsetHeight / 1080
        );
        iframe.style.transform = `scale(${scale})`;
    };

    useEffect(() => {

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div
            className="interactive-screen-main-enabled-grid-wrapper"
            id="interactive-screen-main-enabled-grid-wrapper"
        >
            <iframe
                className="interactive-screen-main-enabled-grid-src"
                id="interactive_main_grid_src"
                src={link}
                allowTransparency="true"
            />
            <p>[1920 x 1080 px]</p>
        </div>
    );
};

export default InteractiveEnabledGrid;