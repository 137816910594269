import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import "./Points.scss";

const containerVariants = {
    hidden: { opacity: 0 },
    show: {
        opacity: 1,
        transition: {
        staggerChildren: 0.1, // Stagger the animation of children by 0.1 second
        }
    },
    exit: {
        opacity: 0,
        transition: {
        staggerChildren: 0.1, // Stagger the exit animation of children by 0.1 second
        staggerDirection: -1, // Reverse the stagger direction for exit animation
        }
    }
};

const childVariants = {
    hidden: { opacity: 0, y: 10 },
    show: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -10 }
};


const Points = ({config, socket}) => {

    const [data, setData] = useState(null);
    const [currentPage, setCurrentPage] = useState(0);
    const palette = config?.palette?.palette;

    useEffect(() => {
        if (!config?.selectedPointsData) return;
        socket.emit("points", {command: "GET", id: config?.selectedPointsData}, (response) => {
            setData(response?.data?.data?.data); // ?????????? lmao
        });
    }, [config?.selectedPointsData]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentPage(prevPage => {
                const totalPages = Math.ceil(data?.competitors.length / 15);
                return (prevPage + 1) % totalPages;
            });
        }, 13 * 1000);

        return () => clearInterval(intervalId);
    }, [data]);

    if (!data) {
        return <></>;
    }

    const competitorsPerPage = data.competitors.slice(currentPage * 15, (currentPage + 1) * 15);

    switch(config?.style) {
        case "v2": {
            return (
                <div
                    className="v2_points_graphic"
                    style={{
                        left: config?.buttons?.pointStandings?.left,
                        top: config?.buttons?.pointStandings?.top
                    }}
                >
                    <motion.header
                        initial={{y: -50, opacity: 0}}
                        animate={{y: 0, opacity: 1}}
                        transition={{delay: 0.1, duration: 0.2, bounce: false}}
                        exit={{clipPath: 'inset(100% 0 0 0)', transition: {delay: 0.35}}}
                        style={{background: palette?.primary}}
                    >
                        <p>CURRENT STANDINGS</p>
                    </motion.header>
                    <div className="v2_points_graphic_header">
                        <motion.div
                            className="v2_points_graphic_header_item"
                            initial={{ clipPath: 'inset(0 0 0 100%)' }}
                            animate={{ clipPath: 'inset(0 0 0 0)' }}
                            exit={{clipPath: 'inset(0 0 100% 0)', transition: {delay: 0.35}}}
                            transition={{delay: 0.5, duration: 0.1}}
                            style={{background: palette?.secondary}}
                        >
                            <p>{data.header.race_date}</p>
                        </motion.div>
                        <motion.div
                            className="v2_points_graphic_header_item_mid"
                            initial={{ scaleY: 0, opacity: 0, originY: 0 }}
                            animate={{ scaleY: 1, opacity: 1 }}
                            exit={{clipPath: 'inset(0 0 100% 0)', transition: {delay: 0.35}}}
                            transition={{delay: 0.3, duration: 0.1, bounce: false}}
                            style={{background: palette?.secondary}}
                        ><p>{data.header.race_name}</p></motion.div>
                        <motion.div
                            className="v2_points_graphic_header_item"
                            initial={{ clipPath: 'inset(0 100% 0 0)' }}
                            animate={{ clipPath: 'inset(0 0 0 0)' }}
                            exit={{clipPath: 'inset(0 0 100% 0)', transition: {delay: 0.35}}}
                            transition={{delay: 0.5, duration: 0.1}}
                            style={{background: palette?.secondary}}
                        ><p>{data.header.race_track}</p></motion.div>
                    </div>
                    <div className="v2_points_graphic_heats">
                    {
                        <>
                        {
                            data.heats.map((heat, index) =>
                                <div
                                    className="v2_points_graphic_heat"
                                    key={`v2_heat_${index}`}
                                    style={{background: palette?.odd}}
                                >
                                    <p>{heat.shortname}</p>
                                </div>
                            )
                        }
                        <div
                            className="v2_points_graphic_heat total"
                            key={`v2_heat_total`}
                        >
                            <p>TOTAL</p>
                        </div>
                        </>
                    }
                    </div>
                    <div className="v2_points_graphic_competitors">
                    {
                        competitorsPerPage.map((competitor, index) =>
                            <div
                                className="v2_points_graphic_competitor"
                                key={`v2_competitor_${index}`}
                                style={{background: index % 2 === 0 ? palette?.even : palette?.odd}}
                            >
                                <div className="v2_points_item_pos">
                                    <span>{competitor.position}</span>
                                </div>
                                <div style={{color: palette?.accent}} className="v2_points_item_nr">{competitor.nr}</div>
                                <div className="v2_points_item_name">{competitor.firstname}</div>
                                <div className="v2_points_item_name bold">{competitor.lastname}</div>
                                <div className="v2_points_item_class">{competitor.class}</div>
                                <div className="v2_points_graphic_competitor_points">
                                {
                                    competitor.heats?.map((heat, index2) =>
                                        <div className="v2_points_graphic_heat" key={`v2_heat_item_${index2}${index}`}>
                                            <p>{heat.points}</p>
                                        </div>
                                    )
                                }
                                </div>
                                <div
                                    className="v2_points_graphic_competitor_points_total"
                                    style={{background: palette?.accent}}
                                >
                                    <p>{competitor.total}</p>
                                </div>
                            </div>
                        )
                    }
                    </div>
                </div>
            );
        }
        case "k1": return (
            <div
                className="k1_points w-fit h-fit text-white absolute"
                style={{
                    left: config?.buttons?.pointStandings?.left,
                    top: config?.buttons?.pointStandings?.top
                }}
            >
                <motion.header
                    initial={{y: -50, opacity: 0}}
                    animate={{y: 0, opacity: 1}}
                    transition={{delay: 0.1, duration: 0.2, bounce: false}}
                    exit={{clipPath: 'inset(100% 0 0 0)', transition: {delay: 0.35}}}
                    className="w-full h-[100px] bg-[#1f2227] px-[10px] flex items-center justify-center"
                >
                    <p className="text-[2rem] text-[#f7d00a]">CURRENT STANDINGS</p>
                </motion.header>
                <motion.div
                    className="w-full h-[60px] p-[10px] flex items-center bg-[#1f2227]"
                    initial={{y: 50, opacity: 0}}
                    animate={{y: 0, opacity: 1}}
                    transition={{delay: 0.1, duration: 0.2, bounce: false}}
                    exit={{clipPath: 'inset(0 0 100% 0)', transition: {delay: 0.35}}}
                >
                    <motion.div
                        className="w-1/4 py-[10px] h-full flex items-center justify-center"
                        initial={{ clipPath: 'inset(0 0 0 100%)' }}
                        animate={{ clipPath: 'inset(0 0 0 0)' }}
                        exit={{clipPath: 'inset(0 0 100% 0)', transition: {delay: 0.35}}}
                        transition={{delay: 0.5, duration: 0.1}}
                    >
                        <p>{data.header.race_date}</p>
                    </motion.div>
                    <motion.div
                        className="w-2/4 py-[10px] h-full flex items-center justify-center"
                        initial={{ scaleY: 0, opacity: 0, originY: 0 }}
                        animate={{ scaleY: 1, opacity: 1 }}
                        exit={{clipPath: 'inset(0 0 100% 0)', transition: {delay: 0.35}}}
                        transition={{delay: 0.3, duration: 0.1, bounce: false}}
                    ><p>{data.header.race_name}</p></motion.div>
                    <motion.div
                        className="w-1/4 py-[10px] h-full flex items-center justify-center"
                        initial={{ clipPath: 'inset(0 100% 0 0)' }}
                        animate={{ clipPath: 'inset(0 0 0 0)' }}
                        exit={{clipPath: 'inset(0 0 100% 0)', transition: {delay: 0.35}}}
                        transition={{delay: 0.5, duration: 0.1}}

                    ><p>{data.header.race_track}</p></motion.div>
                </motion.div>
                <motion.div
                    className="w-full h-[40px] border-t-[2px] flex justify-end bg-[#ea324d]"
                    initial={{ opacity: 0, clipPath: 'inset(0 100% 0 0)' }}
                    animate={{ opacity: 1, clipPath: 'inset(0 0 0 0)' }}
                    exit={{ opacity: 0, clipPath: 'inset(0 100% 0 0)' }}
                    transition={{ duration: 0.2, delay: 0.3, bounce: false }}
                >
                    {
                        <>
                            {
                                data.heats.map((heat, index) =>
                                    <motion.div
                                        key={`k1_heat_${index}`}
                                        className="w-[120px] bg-[#ea324d] flex items-center justify-center"
                                        initial={{ opacity: 0, x: -10 }}
                                        animate={{ opacity: 1, x: 0 }}
                                        exit={{ opacity: 0, x: 10 }}
                                        transition={{ duration: 0.2 }}
                                    >
                                        <p>{heat.shortname}</p>
                                    </motion.div>
                                )
                            }
                            <div className="w-[120px] bg-[#ea324d] flex items-center justify-center" key={`k1_heat_total`}>
                                <motion.p
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}
                                    transition={{ duration: 0.2 }}
                                >TOTAL</motion.p>
                            </div>
                        </>
                    }
                </motion.div>
                {
                    data.competitors.length > 15 &&
                    <motion.div
                        className="w-full h-[5px] bg-[#1f2227]"
                        initial={{ opacity: 0, clipPath: 'inset(0 100% 0 0)' }}
                        animate={{ opacity: 1, clipPath: 'inset(0 0 0 0)' }}
                        exit={{ opacity: 0, clipPath: 'inset(0 100% 0 0)' }}
                        transition={{ duration: 0.2, delay: 0.3, bounce: false }}
                    >
                        <div className="points_animation bg-white h-full"/>
                    </motion.div>
                }
                <motion.div
                    variants={containerVariants}
                    initial="hidden"
                    animate="show"
                    exit="exit"
                    className="w-full h-fit border-t-[2px]"
                    layout
                >
                    {
                        competitorsPerPage.map((competitor, index) =>
                            <motion.li
                                className="w-full h-[50px] flex items-center bg-[#1f2227ea]"
                                key={`v2_competitor_${index}${competitor?.nr}${currentPage}`}
                                initial={{ opacity: 0, clipPath: 'inset(0 100% 0 0)'}}
                                animate={{ opacity: 1, clipPath: 'inset(0 0 0 0)'}}
                                exit={{ opacity: 0, clipPath: 'inset(0 100% 0 0)'}}
                                transition={{ duration: 0.3, delay: 0.2 + index * 0.05 }}
                                layout
                            >
                                <div className="w-[50px] flex items-center justify-center">
                                    <span
                                        className="w-[40px] h-[40px] bg-white rounded-sm text-black flex items-center justify-center text-[1.2rem] font-bold"
                                    >{competitor.position}</span>
                                </div>
                                <div className="w-[50px] pl-3 text-[1.1rem] text-[#f7d00a] text-f1-bold">{competitor.nr}</div>
                                <div className="w-[240px] pl-3 text-[1.2rem] overflow-hidden text-ellipsis whitespace-nowrap">{competitor.firstname}</div>
                                <div className="w-[240px] pl-3 text-[1.2rem] overflow-hidden text-ellipsis whitespace-nowrap font-bold">{competitor.lastname}</div>
                                <div className="w-[140px] pl-3 text-[1.2rem] overflow-hidden text-ellipsis whitespace-nowrap">{competitor.class}</div>
                                <div className="h-full flex border-l border-l-white">
                                {
                                    competitor.heats?.map((heat, index2) =>
                                        <motion.div
                                            className="w-[120px] h-full flex items-center justify-center text-[1.3rem]"
                                            key={`v2_heat_item_${index2}${index}`}
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: 1 }}
                                            exit={{ opacity: 0 }}
                                            transition={{ duration: 0.2 }}
                                        >
                                            <p>{heat.points}</p>
                                        </motion.div>
                                    )
                                }
                                </div>
                                <div className="w-[120px] h-full flex items-center justify-center text-[1.3rem] bg-[#ea324dea]">
                                    <p>{competitor.total}</p>
                                </div>
                            </motion.li>
                        )
                    }
                </motion.div>
            </div>
        );
    }
};
export default Points;