import { useEffect, useState } from "react";
import useAxiosPrivate from "./useAxiosPrivate";
import useAuth from "./useAuth";

const useTokenTime = (socket) => {

    const { auth } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const [ tokenTime, setTokenTime ] = useState();
    const [ loading, setLoading ] = useState(true);

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        const getTokenTime = async () => {
            try {
                const response = await axiosPrivate.post('/timeLeft', {
                    userId: auth.userId,
                    signal: controller.signal
                });
                if (isMounted) {
                    setTokenTime(response.data);
                    setLoading(false);
                }
            } catch (err) {
                console.error(err);
                setTokenTime("Error");
                setLoading(false);
            }
        }
        // TODO: calc remaining time in front
        getTokenTime();

        const intervalId = setInterval(getTokenTime, 60 * 1000);

        // Listen for 'tokenExpired' and 'tokenActivated' events
        socket.on('tokenExpired', getTokenTime);
        socket.on('tokenActivated', getTokenTime);

        return () => {
            isMounted = false;
            controller.abort();
            clearInterval(intervalId);

            socket.off('tokenExpired', getTokenTime);
            socket.off('tokenActivated', getTokenTime);
        }
    }, []);

    return { tokenTime, loading };
}

export default useTokenTime;